import React from 'react';
import TabThree from '../../components/Tabs/IndustriesServices';
import IndustriesHero from './IndustriesHero';
import PageMeta from '../../components/PageMeta';
import Layout from '../../layout';
import NavRightOne from '../../components/NavRight/NavRightOne';
import Navbar from '../../layout/Header/Navbar';
import FooterOne from '../../layout/Footer/FooterOne';

const HomeCorporate = () => {
  return (
    <Layout>
      <PageMeta title='Industries - Apoyphe' />
      <Navbar logoDark navRight={<NavRightOne warningBtn />}/>
      <div id='main_content'>
        <IndustriesHero/>
        <TabThree />
        <FooterOne />
      </div>
    </Layout>
  );
};

export default HomeCorporate;
