import React, { Component } from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../SectionTitle/SectionTitle";

class TabThree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSection: "agriculture", // Set default selected section to 'agriculture'
    };
  }

  // Method to handle button clicks and update the selected section
  handleSectionClick = (section) => {
    this.setState({ selectedSection: section });
  };

  render() {
    const { selectedSection } = this.state;

    return (
      <React.Fragment>
        <section className="feature-tabs-three">
          <div className="container-fluid">
            <SectionTitle
              firstTitle="A multifunctional and"
              lastTitle="safe business"
              description="So I said knees up cuppa such a fibber Jeffrey a bit of how's
                yours."
              classOption="text-center"
            />
            <div
              className="tt-feature-tab-two wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <nav>
                <div
                  className="nav d-flex justify-content-center nav-pills tt-tabs-navigation feature-tab-two-nav"
                  id="nav-tab"
                  role="tablist"
                >
                  <a
                    className="nav-link tab-nav-item feature-tab-two-nav__item active"
                    id="nav-home-tab"
                    data-toggle="tab"
                    href="#nav-home"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true"
                    onClick={() => this.handleSectionClick("agriculture")}
                  >
                    <div className="tab-icon">
                      {/* <img src="media/feature/tab1.svg" alt="tab" /> */}
                      <i class="fa-solid fa-tractor"></i>
                    </div>
                    <div className="nav_content">
                      <h4 className="feature-tab-two-nav__title">
                        Agriculture
                      </h4>
                    </div>
                  </a>

                  <a
                    className="nav-link tab-nav-item feature-tab-two-nav__item"
                    id="nav-profile-tab"
                    data-toggle="tab"
                    href="#nav-profile"
                    role="tab"
                    aria-controls="nav-profile"
                    aria-selected="false"
                    onClick={() => this.handleSectionClick("healthcare")}
                  >
                    <div className="tab-icon">
                      {/* <img src="media/feature/tab2.svg" alt="tab" /> */}
                      <i class="fa-solid fa-notes-medical"></i>
                    </div>

                    <div className="nav_content">
                      <h4 className="feature-tab-two-nav__title">HealthCare</h4>
                    </div>
                  </a>

                  <a
                    className="nav-link tab-nav-item feature-tab-two-nav__item"
                    id="nav-profile-tab"
                    data-toggle="tab"
                    href="#nav-profile"
                    role="tab"
                    aria-controls="nav-profile"
                    aria-selected="false"
                    onClick={() => this.handleSectionClick("manufacturing")}
                  >
                    <div className="tab-icon">
                      {/* <img src="media/feature/tab2.svg" alt="tab" /> */}
                      <i class="bi bi-tools"></i>
                    </div>

                    <div className="nav_content">
                      <h4 className="feature-tab-two-nav__title">
                        Manufacturing
                      </h4>
                    </div>
                  </a>

                  <a
                    className="nav-link tab-nav-item feature-tab-two-nav__item"
                    id="nav-profile-tab"
                    data-toggle="tab"
                    href="#nav-profile"
                    role="tab"
                    aria-controls="nav-profile"
                    aria-selected="false"
                    onClick={() => this.handleSectionClick("retail")}
                  >
                    <div className="tab-icon">
                      {/* <img src="media/feature/tab2.svg" alt="tab" /> */}
                      <i class="bi bi-box-fill"></i>
                    </div>

                    <div className="nav_content">
                      <h4 className="feature-tab-two-nav__title">Retail</h4>
                    </div>
                  </a>
                </div>
              </nav>

              <div>
                {/* agriculture section */}
                {selectedSection === "agriculture" && (
                  <div
                    className="mt-4 tab-content tt-tabs-content feature-tab-two"
                    id="nav-tabContent"
                  >
                    <section className="image-content-area agriculture-section">
                      <div className="container">
                        <div className="row justify-content-between">
                          <div className="col-xl-5">
                            <img
                              className="wow fadeInUp"
                              src="media/Industries/agriculture.webp"
                              alt="banner"
                            />
                          </div>

                          <div className="col-xl-6">
                            <div className="image-content">
                              <SectionTitle
                                classOption="style-one"
                                firstTitle="Increase your business growth & sales"
                                description=" He lost his bottle in my flat my good sir bottle in my flat my good sir chap my lady boot in my flat my good sir chap my lady boot"
                                descriptionTwo="is car boot well smashing crikey, are you taking the piss flat my good sir chap my lady boot in my flat my good sir chap my lady boot"
                                descriptionThree="lost the plot it's all gone in my flat my good sir chap my lady boot in my flat my good sir chap my lady boot."
                              />

                              <h4
                                className="title wow fadeInUp"
                                data-wow-delay="0.7s"
                              >
                                Discover the widgets
                              </h4>

                              <a
                                href="about"
                                className="tt__btn btn-sqr btn-outline wow fadeInUp mt-5"
                                data-wow-delay="1.1s"
                              >
                                Read More
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                    <div className="service-three area">
                      <div className="container">
                        <SectionTitle
                          firstTitle="Ultraland"
                          lastTitle="Trending feature"
                          description="So I said knees up cuppa such a fibber jeffrey a bit of how's your."
                        />
                        <div className="row">
                          <div className="col-md-4">
                            <div
                              className="tt-icon-box style-three wow fadeInUp"
                              data-wow-delay="0.7s"
                            >
                              <div className="icon-container">
                                <img
                                  src="media/feature/trending-up.svg"
                                  alt="Direct Access"
                                />
                              </div>

                              <div className="box-content">
                                <h4 className="box-title">
                                  <Link to="/service">Direct Access</Link>
                                </h4>

                                <p className="description">
                                  Naff buggered I Eaton grub cheers show off
                                  show off pick your nose and blow off give us a
                                  bell.
                                </p>
                                <Link to="/service" className="tt-more-link">
                                  Read More
                                  <i className="feather-arrow-right"></i>
                                </Link>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div
                              className="tt-icon-box style-three wow fadeInUp"
                              data-wow-delay="0.9s"
                            >
                              <div className="icon-container color__two">
                                <img
                                  src="media/feature/bell.svg"
                                  alt="Push Notification"
                                />
                              </div>

                              <div className="box-content">
                                <h4 className="box-title">
                                  <Link to="service">Push Notification</Link>
                                </h4>

                                <p className="description">
                                  Naff buggered I Eaton grub cheers show off
                                  show off pick your nose and blow off give us a
                                  bell.
                                </p>

                                <Link to="/service" className="tt-more-link">
                                  Read More
                                  <i className="feather-arrow-right"></i>
                                </Link>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div
                              className="tt-icon-box style-three wow fadeInUp"
                              data-wow-delay="1.1s"
                            >
                              <div className="icon-container color__three">
                                <img
                                  src="media/feature/tablet.svg"
                                  alt="Mobile Capability"
                                />
                              </div>

                              <div className="box-content">
                                <h4 className="box-title">
                                  <Link to="service">Mobile Capability</Link>
                                </h4>

                                <p className="description">
                                  Naff buggered I Eaton grub cheers show off
                                  show off pick your nose and blow off give us a
                                  bell.
                                </p>

                                <Link to="/service" className="tt-more-link">
                                  Read More
                                  <i className="feather-arrow-right"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="service-three area">
                      <div className="container">
                        <SectionTitle
                          firstTitle="Ultraland"
                          lastTitle="Trending feature"
                          description="So I said knees up cuppa such a fibber jeffrey a bit of how's your."
                        />
                        <div className="row">
                          <div className="col-md-4">
                            <div
                              className="tt-icon-box style-three wow fadeInUp"
                              data-wow-delay="0.7s"
                            >
                              <div className="icon-container">
                                <img
                                  src="media/feature/trending-up.svg"
                                  alt="Direct Access"
                                />
                              </div>

                              <div className="box-content">
                                <h4 className="box-title">
                                  <Link to="/service">Direct Access</Link>
                                </h4>

                                <p className="description">
                                  Naff buggered I Eaton grub cheers show off
                                  show off pick your nose and blow off give us a
                                  bell.
                                </p>
                                <Link to="/service" className="tt-more-link">
                                  Read More
                                  <i className="feather-arrow-right"></i>
                                </Link>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div
                              className="tt-icon-box style-three wow fadeInUp"
                              data-wow-delay="0.9s"
                            >
                              <div className="icon-container color__two">
                                <img
                                  src="media/feature/bell.svg"
                                  alt="Push Notification"
                                />
                              </div>

                              <div className="box-content">
                                <h4 className="box-title">
                                  <Link to="service">Push Notification</Link>
                                </h4>

                                <p className="description">
                                  Naff buggered I Eaton grub cheers show off
                                  show off pick your nose and blow off give us a
                                  bell.
                                </p>

                                <Link to="/service" className="tt-more-link">
                                  Read More
                                  <i className="feather-arrow-right"></i>
                                </Link>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div
                              className="tt-icon-box style-three wow fadeInUp"
                              data-wow-delay="1.1s"
                            >
                              <div className="icon-container color__three">
                                <img
                                  src="media/feature/tablet.svg"
                                  alt="Mobile Capability"
                                />
                              </div>

                              <div className="box-content">
                                <h4 className="box-title">
                                  <Link to="service">Mobile Capability</Link>
                                </h4>

                                <p className="description">
                                  Naff buggered I Eaton grub cheers show off
                                  show off pick your nose and blow off give us a
                                  bell.
                                </p>

                                <Link to="/service" className="tt-more-link">
                                  Read More
                                  <i className="feather-arrow-right"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* HealthCare section  */}
                {selectedSection === "healthcare" && (
                  <div
                    className="mt-4 tab-content tt-tabs-content feature-tab-two"
                    id="nav-tabContent"
                  >
                    <section className="image-content-area agriculture-section">
                      <div className="container">
                        <div className="row justify-content-between">
                          <div className="col-xl-6">
                            <div className="image-content">
                              <SectionTitle
                                classOption="style-one"
                                firstTitle="Increase your business growth & sales"
                                description=" He lost his bottle in my flat my good sir chap my lady  my lady boot in my flat my good sir chap my lady boot boot my flat my good sir my lady boot "
                                descriptionTwo="is car boot well smashing crikey, are you taking the piss  my lady boot in my flat my good sir chap my lady boot my lady boot my lady boot"
                                descriptionThree="lost the plot it's all gone  my lady boot in my flat my good sir chap my lady boot my lady boot my lady boot my lady boot my lady boot."
                              />

                              <h4
                                className="title wow fadeInUp"
                                data-wow-delay="0.7s"
                              >
                                Discover the widgets
                              </h4>

                              <a
                                href="about"
                                className="tt__btn btn-sqr btn-outline wow fadeInUp mt-5"
                                data-wow-delay="1.1s"
                              >
                                Read More
                              </a>
                            </div>
                          </div>

                          <div className="col-xl-5">
                            <div className="tt-parallax__image tt-parallax__image--one">
                              <div className="shape-image">
                                <img
                                  className="wow fadeInUp"
                                  src="media/Industries/healthcare.webp"
                                  alt="banner"
                                />
                              </div>

                              {/* <div className="parallax-image">
                                <div className="parallax-image-wrapper">
                                  <ul className="parallax-image-list">
                                    <li
                                      className="wow fadeInUp"
                                      data-wow-delay="0.3s"
                                    >
                                      <img
                                        src="media/image-content/img_01.png"
                                        alt=""
                                      />
                                    </li>
                                    <li
                                      className="wow fadeInUp"
                                      data-wow-delay="0.3s"
                                    >
                                      <img
                                        src="media/image-content/img_02.png"
                                        alt=""
                                      />
                                    </li>
                                    <li
                                      className="wow fadeInUp"
                                      data-wow-delay="0.5s"
                                    >
                                      <img
                                        src="media/image-content/img_03.png"
                                        alt=""
                                      />
                                    </li>
                                    <li
                                      className="wow fadeInUp"
                                      data-wow-delay="0.5s"
                                    >
                                      <img
                                        src="media/image-content/img_04.png"
                                        alt=""
                                      />
                                    </li>
                                  </ul>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                    <div className="service-three area">
                      <div className="container">
                        <SectionTitle
                          firstTitle="Ultraland"
                          lastTitle="Trending feature"
                          description="So I said knees up cuppa such a fibber jeffrey a bit of how's your."
                        />
                        <div className="row">
                          <div className="col-md-4">
                            <div
                              className="tt-icon-box style-three wow fadeInUp"
                              data-wow-delay="0.7s"
                            >
                              <div className="icon-container">
                                <img
                                  src="media/feature/trending-up.svg"
                                  alt="Direct Access"
                                />
                              </div>

                              <div className="box-content">
                                <h4 className="box-title">
                                  <Link to="/service">Direct Access</Link>
                                </h4>

                                <p className="description">
                                  Naff buggered I Eaton grub cheers show off
                                  show off pick your nose and blow off give us a
                                  bell.
                                </p>
                                <Link to="/service" className="tt-more-link">
                                  Read More
                                  <i className="feather-arrow-right"></i>
                                </Link>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div
                              className="tt-icon-box style-three wow fadeInUp"
                              data-wow-delay="0.9s"
                            >
                              <div className="icon-container color__two">
                                <img
                                  src="media/feature/bell.svg"
                                  alt="Push Notification"
                                />
                              </div>

                              <div className="box-content">
                                <h4 className="box-title">
                                  <Link to="service">Push Notification</Link>
                                </h4>

                                <p className="description">
                                  Naff buggered I Eaton grub cheers show off
                                  show off pick your nose and blow off give us a
                                  bell.
                                </p>

                                <Link to="/service" className="tt-more-link">
                                  Read More
                                  <i className="feather-arrow-right"></i>
                                </Link>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div
                              className="tt-icon-box style-three wow fadeInUp"
                              data-wow-delay="1.1s"
                            >
                              <div className="icon-container color__three">
                                <img
                                  src="media/feature/tablet.svg"
                                  alt="Mobile Capability"
                                />
                              </div>

                              <div className="box-content">
                                <h4 className="box-title">
                                  <Link to="service">Mobile Capability</Link>
                                </h4>

                                <p className="description">
                                  Naff buggered I Eaton grub cheers show off
                                  show off pick your nose and blow off give us a
                                  bell.
                                </p>

                                <Link to="/service" className="tt-more-link">
                                  Read More
                                  <i className="feather-arrow-right"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="service-three area">
                      <div className="container">
                        <SectionTitle
                          firstTitle="Ultraland"
                          lastTitle="Trending feature"
                          description="So I said knees up cuppa such a fibber jeffrey a bit of how's your."
                        />
                        <div className="row">
                          <div className="col-md-4">
                            <div
                              className="tt-icon-box style-three wow fadeInUp"
                              data-wow-delay="0.7s"
                            >
                              <div className="icon-container">
                                <img
                                  src="media/feature/trending-up.svg"
                                  alt="Direct Access"
                                />
                              </div>

                              <div className="box-content">
                                <h4 className="box-title">
                                  <Link to="/service">Direct Access</Link>
                                </h4>

                                <p className="description">
                                  Naff buggered I Eaton grub cheers show off
                                  show off pick your nose and blow off give us a
                                  bell.
                                </p>
                                <Link to="/service" className="tt-more-link">
                                  Read More
                                  <i className="feather-arrow-right"></i>
                                </Link>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div
                              className="tt-icon-box style-three wow fadeInUp"
                              data-wow-delay="0.9s"
                            >
                              <div className="icon-container color__two">
                                <img
                                  src="media/feature/bell.svg"
                                  alt="Push Notification"
                                />
                              </div>

                              <div className="box-content">
                                <h4 className="box-title">
                                  <Link to="service">Push Notification</Link>
                                </h4>

                                <p className="description">
                                  Naff buggered I Eaton grub cheers show off
                                  show off pick your nose and blow off give us a
                                  bell.
                                </p>

                                <Link to="/service" className="tt-more-link">
                                  Read More
                                  <i className="feather-arrow-right"></i>
                                </Link>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div
                              className="tt-icon-box style-three wow fadeInUp"
                              data-wow-delay="1.1s"
                            >
                              <div className="icon-container color__three">
                                <img
                                  src="media/feature/tablet.svg"
                                  alt="Mobile Capability"
                                />
                              </div>

                              <div className="box-content">
                                <h4 className="box-title">
                                  <Link to="service">Mobile Capability</Link>
                                </h4>

                                <p className="description">
                                  Naff buggered I Eaton grub cheers show off
                                  show off pick your nose and blow off give us a
                                  bell.
                                </p>

                                <Link to="/service" className="tt-more-link">
                                  Read More
                                  <i className="feather-arrow-right"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Manufacturing section  */}
                {selectedSection === "manufacturing" && (
                  <div
                    className="mt-4 tab-content tt-tabs-content feature-tab-two"
                    id="nav-tabContent"
                  >
                    <section className="image-content-area agriculture-section">
                      <div className="container">
                        <div className="row justify-content-between">
                          <div className="col-xl-5">
                            <img
                              className="wow fadeInUp"
                              src="media/Industries/manufacturing.webp"
                              alt="banner"
                            />

                            {/* <div className="parallax-image">
                                <div className="parallax-image-wrapper">
                                  <ul className="parallax-image-list">
                                    <li
                                      className="wow fadeInUp"
                                      data-wow-delay="0.3s"
                                    >
                                      <img
                                        src="media/image-content/img_01.png"
                                        alt=""
                                      />
                                    </li>
                                    <li
                                      className="wow fadeInUp"
                                      data-wow-delay="0.3s"
                                    >
                                      <img
                                        src="media/image-content/img_02.png"
                                        alt=""
                                      />
                                    </li>
                                    <li
                                      className="wow fadeInUp"
                                      data-wow-delay="0.5s"
                                    >
                                      <img
                                        src="media/image-content/img_03.png"
                                        alt=""
                                      />
                                    </li>
                                    <li
                                      className="wow fadeInUp"
                                      data-wow-delay="0.5s"
                                    >
                                      <img
                                        src="media/image-content/img_04.png"
                                        alt=""
                                      />
                                    </li>
                                  </ul>
                                </div>
                              </div> */}
                          </div>
                          <div className="col-xl-6">
                            <div className="image-content">
                              <SectionTitle
                                classOption="style-one"
                                firstTitle="Increase your business growth & sales"
                                description=" He lost his bottle in my flat my good sir chap my lady  my lady boot in my flat my good sir chap my lady boot boot my flat my good sir my lady boot "
                                descriptionTwo="is car boot well smashing crikey, are you taking the piss  my lady boot in my flat my good sir chap my lady boot my lady boot my lady boot"
                                descriptionThree="lost the plot it's all gone  my lady boot in my flat my good sir chap my lady boot my lady boot my lady boot my lady boot my lady boot."
                              />

                              <h4
                                className="title wow fadeInUp"
                                data-wow-delay="0.7s"
                              >
                                Discover the widgets
                              </h4>

                              <a
                                href="about"
                                className="tt__btn btn-sqr btn-outline wow fadeInUp mt-5"
                                data-wow-delay="1.1s"
                              >
                                Read More
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                    <div className="service-three area">
                      <div className="container">
                        <SectionTitle
                          firstTitle="Ultraland"
                          lastTitle="Trending feature"
                          description="So I said knees up cuppa such a fibber jeffrey a bit of how's your."
                        />
                        <div className="row">
                          <div className="col-md-4">
                            <div
                              className="tt-icon-box style-three wow fadeInUp"
                              data-wow-delay="0.7s"
                            >
                              <div className="icon-container">
                                <img
                                  src="media/feature/trending-up.svg"
                                  alt="Direct Access"
                                />
                              </div>

                              <div className="box-content">
                                <h4 className="box-title">
                                  <Link to="/service">Direct Access</Link>
                                </h4>

                                <p className="description">
                                  Naff buggered I Eaton grub cheers show off
                                  show off pick your nose and blow off give us a
                                  bell.
                                </p>
                                <Link to="/service" className="tt-more-link">
                                  Read More
                                  <i className="feather-arrow-right"></i>
                                </Link>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div
                              className="tt-icon-box style-three wow fadeInUp"
                              data-wow-delay="0.9s"
                            >
                              <div className="icon-container color__two">
                                <img
                                  src="media/feature/bell.svg"
                                  alt="Push Notification"
                                />
                              </div>

                              <div className="box-content">
                                <h4 className="box-title">
                                  <Link to="service">Push Notification</Link>
                                </h4>

                                <p className="description">
                                  Naff buggered I Eaton grub cheers show off
                                  show off pick your nose and blow off give us a
                                  bell.
                                </p>

                                <Link to="/service" className="tt-more-link">
                                  Read More
                                  <i className="feather-arrow-right"></i>
                                </Link>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div
                              className="tt-icon-box style-three wow fadeInUp"
                              data-wow-delay="1.1s"
                            >
                              <div className="icon-container color__three">
                                <img
                                  src="media/feature/tablet.svg"
                                  alt="Mobile Capability"
                                />
                              </div>

                              <div className="box-content">
                                <h4 className="box-title">
                                  <Link to="service">Mobile Capability</Link>
                                </h4>

                                <p className="description">
                                  Naff buggered I Eaton grub cheers show off
                                  show off pick your nose and blow off give us a
                                  bell.
                                </p>

                                <Link to="/service" className="tt-more-link">
                                  Read More
                                  <i className="feather-arrow-right"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="service-three area">
                      <div className="container">
                        <SectionTitle
                          firstTitle="Ultraland"
                          lastTitle="Trending feature"
                          description="So I said knees up cuppa such a fibber jeffrey a bit of how's your."
                        />
                        <div className="row">
                          <div className="col-md-4">
                            <div
                              className="tt-icon-box style-three wow fadeInUp"
                              data-wow-delay="0.7s"
                            >
                              <div className="icon-container">
                                <img
                                  src="media/feature/trending-up.svg"
                                  alt="Direct Access"
                                />
                              </div>

                              <div className="box-content">
                                <h4 className="box-title">
                                  <Link to="/service">Direct Access</Link>
                                </h4>

                                <p className="description">
                                  Naff buggered I Eaton grub cheers show off
                                  show off pick your nose and blow off give us a
                                  bell.
                                </p>
                                <Link to="/service" className="tt-more-link">
                                  Read More
                                  <i className="feather-arrow-right"></i>
                                </Link>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div
                              className="tt-icon-box style-three wow fadeInUp"
                              data-wow-delay="0.9s"
                            >
                              <div className="icon-container color__two">
                                <img
                                  src="media/feature/bell.svg"
                                  alt="Push Notification"
                                />
                              </div>

                              <div className="box-content">
                                <h4 className="box-title">
                                  <Link to="service">Push Notification</Link>
                                </h4>

                                <p className="description">
                                  Naff buggered I Eaton grub cheers show off
                                  show off pick your nose and blow off give us a
                                  bell.
                                </p>

                                <Link to="/service" className="tt-more-link">
                                  Read More
                                  <i className="feather-arrow-right"></i>
                                </Link>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div
                              className="tt-icon-box style-three wow fadeInUp"
                              data-wow-delay="1.1s"
                            >
                              <div className="icon-container color__three">
                                <img
                                  src="media/feature/tablet.svg"
                                  alt="Mobile Capability"
                                />
                              </div>

                              <div className="box-content">
                                <h4 className="box-title">
                                  <Link to="service">Mobile Capability</Link>
                                </h4>

                                <p className="description">
                                  Naff buggered I Eaton grub cheers show off
                                  show off pick your nose and blow off give us a
                                  bell.
                                </p>

                                <Link to="/service" className="tt-more-link">
                                  Read More
                                  <i className="feather-arrow-right"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Retail section  */}
                {selectedSection === "retail" && (
                  <div
                    className="mt-4 tab-content tt-tabs-content feature-tab-two"
                    id="nav-tabContent"
                  >
                    <section className="image-content-area agriculture-section">
                      <div className="container">
                        <div className="row">
                          <div className="col-xl-6">
                            <div className="image-content">
                              <SectionTitle
                                classOption="style-one"
                                firstTitle="Increase your business growth & sales"
                                description=" He lost his bottle in my flat my good sir chap my lady  my lady boot in my flat my good sir chap my lady boot boot my flat my good sir my lady boot "
                                descriptionTwo="is car boot well smashing crikey, are you taking the piss  my lady boot in my flat my good sir chap my lady boot my lady boot my lady boot"
                                descriptionThree="lost the plot it's all gone  my lady boot in my flat my good sir chap my lady boot my lady boot my lady boot my lady boot my lady boot."
                              />

                              <h4
                                className="title wow fadeInUp"
                                data-wow-delay="0.7s"
                              >
                                Discover the widgets
                              </h4>

                              <a
                                href="about"
                                className="tt__btn btn-sqr btn-outline wow fadeInUp mt-5"
                                data-wow-delay="1.1s"
                              >
                                Read More
                              </a>
                            </div>
                          </div>

                          <div className="col-xl-5">
                            <img
                              className="wow fadeInUp"
                              src="media/Industries/retail.webp"
                              alt="banner"
                            />

                            {/* <div className="parallax-image">
                                <div className="parallax-image-wrapper">
                                  <ul className="parallax-image-list">
                                    <li
                                      className="wow fadeInUp"
                                      data-wow-delay="0.3s"
                                    >
                                      <img
                                        src="media/image-content/img_01.png"
                                        alt=""
                                      />
                                    </li>
                                    <li
                                      className="wow fadeInUp"
                                      data-wow-delay="0.3s"
                                    >
                                      <img
                                        src="media/image-content/img_02.png"
                                        alt=""
                                      />
                                    </li>
                                    <li
                                      className="wow fadeInUp"
                                      data-wow-delay="0.5s"
                                    >
                                      <img
                                        src="media/image-content/img_03.png"
                                        alt=""
                                      />
                                    </li>
                                    <li
                                      className="wow fadeInUp"
                                      data-wow-delay="0.5s"
                                    >
                                      <img
                                        src="media/image-content/img_04.png"
                                        alt=""
                                      />
                                    </li>
                                  </ul>
                                </div>
                              </div> 
                          </div> */}
                        </div>
                      </div>
                  </div>
                    </section>

              <div className="service-three area">
                <div className="container">
                  <SectionTitle
                    firstTitle="Ultraland"
                    lastTitle="Trending feature"
                    description="So I said knees up cuppa such a fibber jeffrey a bit of how's your."
                  />
                  <div className="row">
                    <div className="col-md-4">
                      <div
                        className="tt-icon-box style-three wow fadeInUp"
                        data-wow-delay="0.7s"
                      >
                        <div className="icon-container">
                          <img
                            src="media/feature/trending-up.svg"
                            alt="Direct Access"
                          />
                        </div>

                        <div className="box-content">
                          <h4 className="box-title">
                            <Link to="/service">Direct Access</Link>
                          </h4>

                          <p className="description">
                            Naff buggered I Eaton grub cheers show off
                            show off pick your nose and blow off give us a
                            bell.
                          </p>
                          <Link to="/service" className="tt-more-link">
                            Read More
                            <i className="feather-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div
                        className="tt-icon-box style-three wow fadeInUp"
                        data-wow-delay="0.9s"
                      >
                        <div className="icon-container color__two">
                          <img
                            src="media/feature/bell.svg"
                            alt="Push Notification"
                          />
                        </div>

                        <div className="box-content">
                          <h4 className="box-title">
                            <Link to="service">Push Notification</Link>
                          </h4>

                          <p className="description">
                            Naff buggered I Eaton grub cheers show off
                            show off pick your nose and blow off give us a
                            bell.
                          </p>

                          <Link to="/service" className="tt-more-link">
                            Read More
                            <i className="feather-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div
                        className="tt-icon-box style-three wow fadeInUp"
                        data-wow-delay="1.1s"
                      >
                        <div className="icon-container color__three">
                          <img
                            src="media/feature/tablet.svg"
                            alt="Mobile Capability"
                          />
                        </div>

                        <div className="box-content">
                          <h4 className="box-title">
                            <Link to="service">Mobile Capability</Link>
                          </h4>

                          <p className="description">
                            Naff buggered I Eaton grub cheers show off
                            show off pick your nose and blow off give us a
                            bell.
                          </p>

                          <Link to="/service" className="tt-more-link">
                            Read More
                            <i className="feather-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="service-three area">
                <div className="container">
                  <SectionTitle
                    firstTitle="Ultraland"
                    lastTitle="Trending feature"
                    description="So I said knees up cuppa such a fibber jeffrey a bit of how's your."
                  />
                  <div className="row">
                    <div className="col-md-4">
                      <div
                        className="tt-icon-box style-three wow fadeInUp"
                        data-wow-delay="0.7s"
                      >
                        <div className="icon-container">
                          <img
                            src="media/feature/trending-up.svg"
                            alt="Direct Access"
                          />
                        </div>

                        <div className="box-content">
                          <h4 className="box-title">
                            <Link to="/service">Direct Access</Link>
                          </h4>

                          <p className="description">
                            Naff buggered I Eaton grub cheers show off
                            show off pick your nose and blow off give us a
                            bell.
                          </p>
                          <Link to="/service" className="tt-more-link">
                            Read More
                            <i className="feather-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div
                        className="tt-icon-box style-three wow fadeInUp"
                        data-wow-delay="0.9s"
                      >
                        <div className="icon-container color__two">
                          <img
                            src="media/feature/bell.svg"
                            alt="Push Notification"
                          />
                        </div>

                        <div className="box-content">
                          <h4 className="box-title">
                            <Link to="service">Push Notification</Link>
                          </h4>

                          <p className="description">
                            Naff buggered I Eaton grub cheers show off
                            show off pick your nose and blow off give us a
                            bell.
                          </p>

                          <Link to="/service" className="tt-more-link">
                            Read More
                            <i className="feather-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div
                        className="tt-icon-box style-three wow fadeInUp"
                        data-wow-delay="1.1s"
                      >
                        <div className="icon-container color__three">
                          <img
                            src="media/feature/tablet.svg"
                            alt="Mobile Capability"
                          />
                        </div>

                        <div className="box-content">
                          <h4 className="box-title">
                            <Link to="service">Mobile Capability</Link>
                          </h4>

                          <p className="description">
                            Naff buggered I Eaton grub cheers show off
                            show off pick your nose and blow off give us a
                            bell.
                          </p>

                          <Link to="/service" className="tt-more-link">
                            Read More
                            <i className="feather-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
                )}
          </div>
        </div>
      </div>
        </section >
      </React.Fragment >
    );
  }
}
export default TabThree;
