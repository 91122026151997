import React from 'react';
import { Link } from 'react-router-dom';
import ImageContentSeven from '../../components/ImageContent/ImageContentSeven';
import LargeCarousel from '../../components/LogoCarousel/LargeCarousel';
import ServiceFour from '../../components/Services/ServiceFour';
import ServiceTwo from '../../components/Services/ServiceTwo';
import ImageContentOne from '../../components/ImageContent/ImageContentOne';
import ImageContentTwo from '../../components/ImageContent/ImageContentTwo';

import TabTwo from '../../components/Tabs/TabTwo';

function HeroOne(){
  return (
    <>
      <section className='banner banner--one banner_bg d-flex align-items-center'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-6 tt-order-2'>
              <div className='banner__content'>
                <h2 className='banner__title wow fadeInUp'>
                Multicloud Services
                </h2>

                <p
                  className='wow fadeInUp banner__description'
                  data-wow-delay='0.3s'
                >

                  Whether transitioning to the cloud or upgrading infrastructure, our scalable platforms streamline operations, boost resilience, and improve customer interactions. We offer scalable multicloud services.

                </p>

                <ul
                  className='banner__feature wow fadeInUp'
                  data-wow-delay='0.9s'
                >
                  <li>
                    <i className='ei ei-icon_check'></i>Hyper-Personalized Services
                  </li>
                  <li>
                    <i className='ei ei-icon_check'></i>Unparalleled Support
                  </li>
                </ul>

                <div className='banner__btns pt-5'>
                  <Link
                    to='/cloud-services'
                    className='tt__btn btn-round banner-btn wow fadeInUp'
                    data-wow-delay='.7s'
                  >
                    View Services
                  </Link>
                </div>
              </div>
            </div>



            <div className='col-lg-6'>
              <div className='banner__feature-image'>


                <div className='banner__feature-multiimage'>
                  <ul className='banner__image-lists'>
                    <li className='image_item'>
                      <img
                        className='wow fadeInDown'
                        data-wow-delay='ms'
                        src='media/home/Home-1.gif'
                        // src='media/banner/07-removebg-preview.png'
                        alt='banner 1'
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>




        {/* <ul className='animate-element parallax-element' id='scene'>
          <li className='layer' data-depth='0.02'>
            <div className='inner'>
              <img
                src='media/banner/element/element1.png'
                alt='Delightful  Learning software 1'
              />
            </div>
          </li>
          <li className='layer' data-depth='0.03'>
            <div className='inner'>
              <img
                src='media/banner/element/element2.png'
                alt='Delightful  Learning software 2'
              />
            </div>
          </li>
          <li className='layer' data-depth='0.04'>
            <div className='inner'>
              <img
                src='media/banner/element/element3.png'
                alt='Delightful  Learning software 3'
              />
            </div>
          </li>
        </ul> */}
      </section>


      <ImageContentSeven />
      <ServiceFour />
      <ServiceTwo />
      <ImageContentOne />
      <ImageContentTwo />
      <LargeCarousel />
      <TabTwo />
    </>
  );
};

export default HeroOne;
