import React from 'react';
// import ServiceOne from '../../components/Services/ServiceOne';
// import ServiceTwo from '../../components/Services/ServiceTwo';
// import TabTwo from '../../components/Tabs/TabTwo';
import HeroTwo from './HeroTwo';
// import IntroOne from '../../components/IntroVideo/IntroOne';
// import TabThree from '../../components/Tabs/TabThree';
// import TestimonialOne from '../../components/Testimonial/TestimonialOne';
// import NewsLetterTwo from '../../components/NewsLetter/NewsLetterTwo';
// import RecentBlog from '../../components/Blogs/RecentBlog';
// import LargeCarousel from '../../components/LogoCarousel/LargeCarousel';
import Layout from '../../layout';
import PageMeta from '../../components/PageMeta';
import FooterOne from '../../layout/Footer/FooterOne';
import NavRightOne from '../../components/NavRight/NavRightOne';
import Navbar from '../../layout/Header/Navbar';



import CloudOperationS1 from '../../components/Services/CloudOperationS1';

// import CloudOperationS2 from '../../components/Services/CloudOperationS2';
// import CloudOperationS3 from '../../components/Services/CloudOperationS3';
// import CloudOperationS4 from '../../components/Services/CloudOperationS4';
// import CloudOperationS5 from '../../components/Services/CloudOperationS5';




const CloudOperations = () => {
  return (
    <Layout>
      <PageMeta title='Cloud Operation - Apoyphe' />
      <Navbar logoDark navRight={<NavRightOne warningBtn />}/>
      <div id='main_content'>
        <HeroTwo />
        <CloudOperationS1/>
        {/* <CloudOperationS2/>
        <CloudOperationS3/>
        <CloudOperationS4/>
        <CloudOperationS5/> */}
        {/* <TabTwo /> */}
        {/* <ServiceOne /> */}
        {/* <ServiceTwo /> */}
        {/* <IntroOne /> */}
        {/* <TabThree />/ */}
        {/* <LargeCarousel /> */}
        {/* <TestimonialOne /> */}
        {/* <RecentBlog /> */}
        {/* <NewsLetterTwo /> */}
        <FooterOne />
      </div>
    </Layout>
  );
};

export default CloudOperations ;
