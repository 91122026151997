import React from 'react';
// import { Link } from 'react-router-dom';

const HeroTwo = () => {
  return (
    <>
      <section className='banner banner--two'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-6 tt-order-lg-2'>
              <div className='banner__content'>
                {/* <h3 className='banner__subtitle wow fadeInUp'>
                  Welcome To Ultraland
                </h3> */}
                <h2
                  className='banner__title wow fadeInUp'
                  data-wow-delay='0.3s'
                >
                  Cloud-First Strategy
                </h2>
                <p
                  className='wow fadeInUp banner__description'
                  data-wow-delay='.5s'
                >
                  Our cloud services are tailored to align with your business goals, delivering agility, scalability, and cost-efficiency while enhancing your digital transformation journey.
 
                </p>
                <div className='banner__btns'>
                  {/* <Link
                    to='/contact'
                    className='tt__btn btn-round btn-outline btn-light banner-btn wow fadeInUp'
                    data-wow-delay='.7s'
                  >
                    Start Free Trial
                  </Link> */}
                </div>
              </div>
            </div>
            <div className='col-lg-5'>
              <div className='banner__feature-image wow fadeIn'>
                <img
                  className='wow fadeInUp'
                  src="media\services\cloud_oprations\cloud-operation-vector1.jpg"
                  alt='banner'
                />
                {/* <svg
                  className='banner__shape'
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  width='100%'
                  height='100%'
                  viewBox='0 0 757 535'
                >
                </svg> */}
              </div>
            </div>
          </div>
        </div>

        {/* <ul className='animate-element banner-two-element parallax-element'>
          <li className='layer' data-depth='0.02'>
            <div className='inner'>
              <img src='media/banner/banner-two/element1.png' alt='element' />
            </div>
          </li>
          <li className='layer' data-depth='0.03'>
            <div className='inner'>
              <img src='media/banner/banner-two/element2.png' alt='element' />
            </div>
          </li>
          <li className='layer' data-depth='0.01'>
            <div className='inner'>
              <img src='media/banner/banner-two/element3.png' alt='element' />
            </div>
          </li>
          <li className='layer' data-depth='0.02'>
            <div className='inner'>
              <img src='media/banner/banner-two/element4.png' alt='element' />
            </div>
          </li>
        </ul> */}
      </section>
    </>
  );
};

export default HeroTwo;
