import React from "react";
import { Link } from "react-router-dom";
// import ModalVideo from 'react-modal-video';
import SectionTitle from "../SectionTitle/SectionTitle";

const ServiceFive = () => {
  // const [isOpen, setOpen] = useState(false);

  return (
    <React.Fragment>
      <div className="service-seven">
        <div className="container">
          <SectionTitle
            firstTitle="Comprehensive"
            lastTitle="IT Solutions"
            description="Tailored IT, cloud, and infrastructure services to drive your business forward."
            classOption="style-one"
          />
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div
                className="tt-icon-box style-eight wow fadeInUp"
                data-wow-delay="0.7s"
              >
                <div className="icon-container">
                
                  <img src="media/feature/email.svg" alt="Data Tracking" />
                </div>

                <div className="box-content">
                  <h4 className="box-title">
                    <Link to="/service"  style={{ pointerEvents: 'none' }}>Mail Us</Link>
                  </h4>
                  <p className="description">
                    For inquiries regarding our services contact us at
                    support@apoyphe.com . Our team to assist.
                  </p>
                  {/* <Link to="/service" className="read-more-btn">
                    Read More<i className="feather-arrow-right"></i>
                  </Link> */}
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div
                className="tt-icon-box style-eight wow fadeInUp"
                data-wow-delay="0.9s"
              >
                <div className="icon-container color__two">
                <img src="media/feature/target.svg" alt="Direct Access" />
                </div>

                <div className="box-content">
                  <h4 className="box-title">
                    <Link to="service"  style={{ pointerEvents: 'none' }}>Cloud Expertise</Link>
                  </h4>
                  <p className="description">
                    Our team ensure seamless cloud services helping your business achieve maximum efficiency.
                  </p>
                  {/* <Link to="/service" className="read-more-btn">
                    Read More<i className="feather-arrow-right"></i>
                  </Link> */}
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div
                className="tt-icon-box style-eight wow fadeInUp"
                data-wow-delay="1.1s"
              >
                <div className="icon-container color__three">
                  <img src="media/feature/bar-graph.svg" alt="Press Releases" />
                </div>

                <div className="box-content">
                  <h4 className="box-title">
                    <Link to="/service"  style={{ pointerEvents: 'none' }}>Managed Services</Link>
                  </h4>
                  <p className="description">
                    We offer dedicated managed services to ensure the smooth operation  of your IT infrastructure.
                  </p>
                  {/* <Link to="/service" className="read-more-btn">
                    Read More<i className="feather-arrow-right"></i>
                  </Link> */}
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div
                className="tt-icon-box style-eight wow fadeInUp"
                data-wow-delay="1.1s"
              >
                <div className="icon-container color__three">
                  <img src="media/feature/headphone.svg" alt="Press Releases" />
                </div>

                <div className="box-content">
                  <h4 className="box-title">
                    <Link to="/service"  style={{ pointerEvents: 'none' }}>Inquiries</Link>
                  </h4>
                  <p className="description">
                    For support-related questions or to learn more about our IT services, reach out to our support team.
                  </p>
                  {/* <Link to="/service" className="read-more-btn">
                    Read More<i className="feather-arrow-right"></i>
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
          {/* <div className='video-thumbnail'>
            <div className='play-button'>
              <ModalVideo
                channel='youtube'
                autoplay
                isOpen={isOpen}
                videoId='9No-FiEInLA'
                onClose={() => setOpen(false)}
              />
              <a
                href='#/'
                className='popup-play-btn'
                onClick={() => setOpen(true)}
              >
                <i className='fas fa-play'></i>
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </React.Fragment>
  );
};
export default ServiceFive;
