import React from 'react';
import ManagedHero from './ManagedHero';
// import ServiceThree from '../../components/Services/ServiceThree';
// import CenterCarousel from '../../components/LogoCarousel/CenterCarousel';
// import ImageContentThree from '../../components/ImageContent/ImageContentThree';
// import ImageContentFour from '../../components/ImageContent/ImageContentFour';
// import ScreenShot from '../../components/AppScreen/ScreenShot';
// import TabFour from '../../components/Tabs/TabFour';
// import TestimonialTwo from '../../components/Testimonial/TestimonialTwo';
// import Counter from '../../components/Counter/Counter';
// import DownloadCta from '../../components/CallToAction/DownloadCta';
import FooterOne from '../../layout/Footer/FooterOne';
import PageMeta from '../../components/PageMeta';
import Layout from '../../layout';
// import NavRightTwo from '../../components/NavRight/NavRightTwo';
import Navbar from '../../layout/Header/Navbar';
import NavRightOne from '../../components/NavRight/NavRightOne';
import ManagedServiceS1 from '../../components/Services/ManagedServiceS1';
// import ManagedServiceS2 from '../../components/Services/ManagedServiceS2';

// import ManagedServiceS3 from '../../components/Services/ManagedServiceS3';
// import ManagedServiceS4 from '../../components/Services/ManagedServiceS4';
// import ManagedServiceS5 from '../../components/Services/ManagedServiceS5';

const ManagedServices = () => {
  return (
    <Layout>
      <PageMeta title='Managed Services - Apoyphe' />
      <Navbar navRight={<NavRightOne warningBtn />} />
      <div id='main_content'>
        <ManagedHero/>
        <ManagedServiceS1/>
        {/* <ManagedServiceS2/>
        <ManagedServiceS3/>
        <ManagedServiceS4/>
        <ManagedServiceS5/> */}
        {/* <ServiceThree /> */}
        {/* <CenterCarousel /> */}
        {/* <ImageContentThree /> */}
        {/* <ImageContentFour /> */}
        {/* <ScreenShot /> */}
        {/* <TabFour /> */}
        {/* <TestimonialTwo /> */}
        {/* <Counter />
        <DownloadCta /> */}
        <FooterOne />
      </div>
    </Layout>
  );
};

export default ManagedServices ;
