import React, { Component } from "react";
import SectionTitle from "../SectionTitle/SectionTitle";

class FeatureOne extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="service">
          <div className="container">
            <SectionTitle
              firstTitle="Hyper-Personalized"
              lastTitle="Cloud & Development Services"
              description="Empowering Businesses with Scalable Cloud Solutions and Comprehensive Development Frameworks, Driving Long-Term Growth, Innovation, and Operational Excellence While Ensuring Flexibility, Security, and Seamless Integration Across Diverse IT Infrastructures."
              classOption="text-center"
            />
            <div className="row justify-content-between">
              <div className="col-md-3 col-sm-6">
                <div
                  className="tt-icon-box style-one wow fadeInUp about-section-head-para"
                  data-wow-delay="0.5s"
                >
                  <div className="icon-container">
                    <img src="media/feature/profile.svg" alt="" />
                  </div>

                  <div className="box-content">
                    <h4 className="box-title">
                      <a href="service.html"  style={{ pointerEvents: 'none' }}>Cloud Infrastructure</a>
                    </h4>

                    <p className="description">
                      Our experts ensure your cloud infrastructure is optimized
                      for performance, security, cost-efficiency and scalablity.
                      <br />
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-3 col-sm-6 offset-md-1">
                <div
                  className="tt-icon-box style-one wow fadeInUp about-section-head-para"
                  data-wow-delay="0.7s"
                >
                  <div className="icon-container">
                    <img src="media/feature/copy.svg" alt="" />
                  </div>

                  <div className="box-content">
                    <h4 className="box-title">
                      <a href="service.html"  style={{ pointerEvents: 'none' }}>Application Development</a>
                    </h4>

                    <p className="description">
                      We offer flexible development frameworks that support
                      modern web and mobile applications, designed to scale your
                      business.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-3 col-sm-6 offset-md-1">
                <div
                  className="tt-icon-box style-one wow fadeInUp about-section-head-para"
                  data-wow-delay="0.9s"
                >
                  <div className="icon-container">
                    <img src="media/feature/bulb.svg" alt="" />
                  </div>

                  <div className="box-content">
                    <h4 className="box-title">
                      <a href="service.html"  style={{ pointerEvents: 'none' }}>DevOps Integration</a>
                    </h4>

                    <p className="description">
                      Integrate DevOps practices to enhance collaboration
                      between development and operations, improving deployment
                      speed.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-3 col-sm-6">
                <div
                  className="tt-icon-box style-one wow fadeInUp about-section-head-para"
                  data-wow-delay="1.1s"
                >
                  <div className="icon-container">
                    <img src="media/feature/analytics.svg" alt="" />
                  </div>

                  <div className="box-content">
                    <h4 className="box-title">
                      <a href="service.html"  style={{ pointerEvents: 'none' }}>Cloud Migration Services</a>
                    </h4>

                    <p className="description">
                      Migrate your legacy systems to the cloud with minimal
                      downtime, ensuring data integrity and enhanced business
                      continuity.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-3 col-sm-6 offset-md-1">
                <div
                  className="tt-icon-box style-one wow fadeInUp about-section-head-para"
                  data-wow-delay="1.3s"
                >
                  <div className="icon-container">
                    <img src="media/feature/hand_shake.svg" alt="" />
                  </div>

                  <div className="box-content">
                    <h4 className="box-title">
                      <a href="service.html"  style={{ pointerEvents: 'none' }}>Cloud-Native Development</a>
                    </h4>

                    <p className="description">
                      Leverage the full potential of cloud platforms with custom
                      cloud-native applications built to be resilient, scalable,
                      and secure.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-3 col-sm-6 offset-md-1">
                <div
                  className="tt-icon-box style-one wow fadeInUp about-section-head-para"
                  data-wow-delay="1.5s"
                >
                  <div className="icon-container">
                    <img src="media/feature/folder.svg" alt="" />
                  </div>

                  <div className="box-content">
                    <h4 className="box-title">
                      <a href="service.html"  style={{ pointerEvents: 'none' }}>Automation Tools</a>
                    </h4>

                    <p className="description">
                      Automate routine tasks and complex workflows to increase
                      productivity and reduce manual errors in your cloud
                      environment.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FeatureOne;
