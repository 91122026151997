import React, { useState } from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../SectionTitle/SectionTitle";

const CloudServicesS1 = () => {
  const [selectedSection, setSelectedSection] = useState("cloudTransformation");

  // Method to handle button clicks and update the selected section
  const handleSectionClick = (section) => {
    setSelectedSection(section);
  };

  return (
    <React.Fragment>
      <section className="feature-tabs-three">
        <div className="container-fluid">
          <SectionTitle
            firstTitle="Scalability across the cloud"
            lastTitle=""
            description="Empowering your business with scalable and secure cloud solutions that are designed to drive innovation and enhance flexibility. "
            classOption="text-center"
          />
          <div
            className="tt-feature-tab-two wow fadeInUp"
            data-wow-delay="0.7s"
          >
            <nav>
              <div
                className="nav d-flex justify-content-center nav-pills tt-tabs-navigation feature-tab-two-nav"
                id="nav-tab"
                role="tablist"
              >
                <a
                  className={
                    "nav-link tab-nav-item feature-tab-two-nav__item active"
                  }
                  id="nav-home-tab"
                  data-toggle="tab"
                  href="#Cloud-Migration"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected={selectedSection === "cloudTransformation"}
                  onClick={() => handleSectionClick("cloudTransformation")}
                >
                  <div className="tab-icon">
                    {/* <img src="media/feature/tab1.svg" alt="tab" /> */}
                    <i class="bi bi-cloud-arrow-down-fill"></i>
                  </div>
                  <div className="nav_content">
                    <h4 className="feature-tab-two-nav__title">
                      Cloud Transformation
                    </h4>
                  </div>
                </a>

                <a
                  className="nav-link tab-nav-item feature-tab-two-nav__item"
                  id="nav-profile-tab"
                  data-toggle="tab"
                  href="#Cloud-Migration"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                  onClick={() => handleSectionClick("CloudMigration")}
                >
                  <div className="tab-icon">
                    {/* <img src="media/feature/tab2.svg" alt="tab" /> */}
                    <i class="bi bi-cloud-fog2-fill"></i>
                  </div>

                  <div className="nav_content">
                    <h4 className="feature-tab-two-nav__title">
                      Cloud Migration
                    </h4>
                  </div>
                </a>
              </div>
            </nav>

            <div>
              {selectedSection === "cloudTransformation" && (
                <div
                  className="mt-4 tab-content tt-tabs-content feature-tab-two"
                  id="nav-tabContent"
                >
                  <section className="image-content-area agriculture-section">
                    <div className="container">
                      <div className="row justify-content-between">
                        <div className="col-xl-5">
                          <div className="tt-parallax__image tt-parallax__image--one">
                            <div className="shape-image">
                              <div
                                className="shape-wrapper wow fadeInLeft"
                                data-wow-dealy="0.3s"
                              >
                                <img
                                  src="media\services\cloud_services\cloud-transformation.png"
                                  alt="cloud-services-img"
                                />
                              </div>
                            </div>

                            {/* <div className="parallax-image">
                              <div className="parallax-image-wrapper">
                                <ul className="parallax-image-list">
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.3s"
                                  >
                                    <img
                                      src="media/image-content/img_01.png"
                                      alt=""
                                    />
                                  </li>
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.3s"
                                  >
                                    <img
                                      src="media/image-content/img_02.png"
                                      alt=""
                                    />
                                  </li>
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.5s"
                                  >
                                    <img
                                      src="media/image-content/img_03.png"
                                      alt=""
                                    />
                                  </li>
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.5s"
                                  >
                                    <img
                                      src="media/image-content/img_04.png"
                                      alt=""
                                    />
                                  </li>
                                </ul>
                              </div>
                            </div> */}
                          </div>
                        </div>

                        <div className="col-xl-6">
                          <div className="image-content">
                            <SectionTitle
                              classOption="style-one"
                              firstTitle="Transforming Businesses with Innovative Cloud Solutions"
                              description=" Embark on a transformative journey with Apoyphe's human-driven cloud solutions We assure unmatched scalability, and security, ensuring your "
                              descriptionTwo="business  thrives with innovation and efficiency. "
                              descriptionThree=" "
                            />

                            {/* <h4
                              className="title wow fadeInUp"
                              data-wow-delay="0.7s"
                            >
                              Discover the widgets
                            </h4> */}

                            {/* <a
                              href="about"
                              className="tt__btn btn-sqr btn-outline wow fadeInUp"
                              data-wow-delay="1.1s"
                            >
                              Read More
                            </a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <div className="service-three area">
                    <div className="container">
                      <SectionTitle
                        firstTitle="Our Speciality"
                        lastTitle=""
                        description="Discover Innovative cloud solution that can help you scale."
                      />
                      <div className="row">
                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.7s"
                          >
                            <div className="icon-container">
                              <img
                                src="media\services\cloud_services\cloud-services-icon1.png"
                                alt="Direct Access"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link
                                  to="/service"
                                  style={{
                                    pointerEvents: "none",
                                  }}
                                >
                                  Cloud Migration
                                </Link>
                              </h4>

                              <p className="description">
                                Apoyphe's expert team will assist you in migrating
                                your data and applications with the utmost
                                security and efficiency.
                              </p>
                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.9s"
                          >
                            <div className="icon-container color__two">
                              <img
                                src="media\services\cloud_services\cloud-services-icon2.png"
                                alt="Push Notification"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Cloud Optimization</Link>
                              </h4>

                              <p className="description">
                                Post-migration, the journey continues as Apoyphe
                                collaborates with your team to optimize the
                                cloud environment.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media\services\cloud_services\cloud-services-icon3.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Managed Services</Link>
                              </h4>

                              <p className="description">
                                Apoyphe goes beyond the transformative journey,
                                offering continuous support through our cloud
                                managed services.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="service-three area">
                    <div className="container">
                      <SectionTitle
                        firstTitle="Transform Your Business with"
                        lastTitle="Apoyphe's Cloud Transformation Solutions"
                        description="We specialize in revolutionizing businesses through seamless cloud transformation. Our tailored strategies ensure that your infrastructure is future-ready, scalable and optimized for performance."
                      />
                      <div className="row">
                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.7s"
                          >
                            <div className="icon-container">
                              <img
                                src="media\services\cloud_services\cloud-services-icon4.png"
                                alt="Direct Access"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="/service" style={{pointerEvents:'none'}}>
                                  Seamless Transformation
                                </Link>
                              </h4>

                              <p className="description">
                              Unlock the full potential of your business by transitioning to the cloud. With Apoyphe's expert guidance, you can navigate the process smoothly and efficiently.
                              </p>
                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.9s"
                          >
                            <div className="icon-container color__two">
                              <img
                                src="media\services\cloud_services\cloud-services-icon5.png"
                                alt="Push Notification"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>
                                  Future-Ready Infrastructure
                                </Link>
                              </h4>

                              <p className="description">
                                We design cloud strategies that not only meet
                                current business needs but are also built to
                                scale for future growth, ensuring long-term
                                agility and adaptability.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media\services\cloud_services\cloud-services-icon6.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Rapid Deployment</Link>
                              </h4>

                              <p className="description">
                              Our cloud transformation services enable swift, efficient deployment of resources, minimizing downtime and ensuring faster time-to-market for critical applications.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media\services\cloud_services\cloud-services-icon7.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Tailored Solutions</Link>
                              </h4>

                              <p className="description">
                                Every business is unique, which is why we
                                customize cloud transformation strategies to
                                align with your specific operational goals,
                                enhancing productivity and collaboration.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media\services\cloud_services\cloud-services-icon8.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>
                                  Compliance and Governance
                                </Link>
                              </h4>

                              <p className="description">
                                We ensure that your cloud environment complies
                                with the latest industry regulations and
                                governance standards, reducing risk and data
                                management practices.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media\services\cloud_services\cloud-services-icon9.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Hybrid and Multi-Cloud</Link>
                              </h4>

                              <p className="description">
                                Leverage the power of hybrid and multi-cloud
                                environments with Apoyphe’s expertise in
                                seamless integration, optimizing performance
                                across diverse cloud platforms.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {selectedSection === "CloudMigration" && (
                <div
                  className="mt-4 tab-content tt-tabs-content feature-tab-two"
                  id="nav-tabContent"
                >
                  <section className="image-content-area agriculture-section">
                    <div className="container">
                      <div className="row">
                        <div className="col-xl-6">
                          <div className="image-content">
                            <SectionTitle
                              classOption="style-one"
                              firstTitle="Accelerate Your Cloud Migration Journey"
                              description="Apoyphe’s cloud migration services are designed to streamline your operations and enhance infrastructure performance. By"
                              descriptionTwo="reducing complexity and ensuring smooth integration, we help your business achieve maximum operational efficiency."
                              descriptionThree=""
                            />

                            {/* <h4
                              className="title wow fadeInUp"
                              data-wow-delay="0.7s"
                            >
                              Discover the widgets
                            </h4> */}

                            {/* <Link
                              href="about"
                              className="tt__btn btn-sqr btn-outline wow fadeInUp"
                              data-wow-delay="1.1s"
                            >
                              Read More
                            </Link> */}
                          </div>
                        </div>

                        <div className="col-xl-6">
                          <div className="tt-parallax__image tt-parallax__image--one">
                            <div className="shape-image">
                              <div
                                className="shape-wrapper wow fadeInLeft"
                                data-wow-dealy="0.3s"
                              >
                                <img
                                  src="media\services\cloud_services\cloud-migration-hero-img.png"
                                  alt="cloud-migration"
                                />
                              </div>
                            </div>

                            {/* <div className="parallax-image">
                              <div className="parallax-image-wrapper">
                                <ul className="parallax-image-list">
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.3s"
                                  >
                                    <img
                                      src="media/image-content/img_01.png"
                                      alt=""
                                    />
                                  </li>
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.3s"
                                  >
                                    <img
                                      src="media/image-content/img_02.png"
                                      alt=""
                                    />
                                  </li>
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.5s"
                                  >
                                    <img
                                      src="media/image-content/img_03.png"
                                      alt=""
                                    />
                                  </li>
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.5s"
                                  >
                                    <img
                                      src="media/image-content/img_04.png"
                                      alt=""
                                    />
                                  </li>
                                </ul>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <div className="service-three area">
                    <div className="container">
                      <SectionTitle
                        firstTitle="Seamless Transition"
                        lastTitle=""
                        description="We ensure a smooth and trouble-free migration to the cloud. Our process is based on tried-and-tested methodologies, reducing downtime and preventing data loss, ensuring a seamless transition to your new cloud environment."
                      />
                      <div className="row">
                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.7s"
                          >
                            <div className="icon-container">
                              <img
                                src="media/services/cloud_services/cloud-migration-icon1.png"
                                alt="Direct Access"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="/service" style={{pointerEvents:'none'}}>Advanced Integration</Link>
                              </h4>

                              <p className="description">
                                Our migration services focus on integrating
                                cloud solutions into your existing IT framework,
                                allowing your business to operate with increased
                                agility and minimized disruption.
                              </p>
                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.9s"
                          >
                            <div className="icon-container color__two">
                              <img
                                src="media\services\cloud_services\cloud-migration-icon2.png"
                                alt="Push Notification"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Optimized Resources</Link>
                              </h4>

                              <p className="description">
                                By leveraging cloud capabilities, we help
                                businesses optimize resource usage and reduce
                                operational costs. Our tailored approach ensures
                                your resources are used efficiently.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media\services\cloud_services\cloud-migration-icon3.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Scalable Solutions</Link>
                              </h4>

                              <p className="description">
                                We deliver migration strategies designed to grow
                                with your business. Our cloud solutions scale as
                                your operational demands increase, supporting
                                long-term growth.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="service-three area">
                    <div className="container">
                      <SectionTitle
                        firstTitle="Future-Ready"
                        lastTitle=""
                        description="Apoyphe’s cloud migration services prepare your business for the future by providing flexible, adaptable cloud infrastructure. We ensure your technology stays ahead of market demands and innovations."
                      />
                      <div className="row">
                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.7s"
                          >
                            <div className="icon-container">
                              <img
                                src="media/services/cloud_services/cloud-migration-icon4.png"
                                alt="Direct Access"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="/service" style={{pointerEvents:'none'}}>Streamlined Processes</Link>
                              </h4>

                              <p className="description">
                                Our cloud migration framework ensures that
                                processes are optimized, enabling faster
                                decision-making capabilities and improved
                                operations across your entire organization.
                              </p>
                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.9s"
                          >
                            <div className="icon-container color__two">
                              <img
                                src="media/services/cloud_services/cloud-migration-icon5.png"
                                alt="Push Notification"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Custom Roadmaps</Link>
                              </h4>

                              <p className="description">
                                We build personalized migration roadmaps based
                                on your business’s unique needs, ensuring a
                                migration plan that aligns perfectly with your
                                goals and minimizes risk.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media/services/cloud_services/cloud-migration-icon6.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Zero Downtime</Link>
                              </h4>

                              <p className="description">
                                We prioritize minimal or zero downtime during
                                migration, ensuring that your business continues
                                running smoothly without interruption to your
                                mission-critical operations.
                              </p>
                              {/* 
                              <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media/services/cloud_services/cloud-migration-icon7.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Comprehensive Security</Link>
                              </h4>

                              <p className="description">
                                Apoyphe ensures robust security measures
                                throughout the migration process, safeguarding
                                your data and ensuring compliance with
                                regulatory standards for data protection and
                                governance.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media/services/cloud_services/cloud-migration-icon8.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Hybrid Cloud Expertise</Link>
                              </h4>

                              <p className="description">
                                We specialize in hybrid cloud environments,
                                ensuring seamless integration between on-premise
                                and cloud-based systems, delivering the
                                flexibility and control your business requires.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media/services/cloud_services/cloud-migration-icon9.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Cost Efficiency</Link>
                              </h4>

                              <p className="description">
                                Our cloud migration strategies are designed to
                                optimize your infrastructure while reducing
                                costs, allowing your business to operate more
                                efficiently while keeping expenses under
                                control.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default CloudServicesS1;
