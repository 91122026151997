import React from 'react';
import ContactFormMap from './ContactFormMap';
import ContactInfo from './ContactInfo';
import PageMeta from '../../components/PageMeta';
import Layout from '../../layout';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
import FooterOne from '../../layout/Footer/FooterOne';
import NavRightOne from '../../components/NavRight/NavRightOne';
import Navbar from '../../layout/Header/Navbar';
import ServiceFive from '../../components/Services/ServiceFive';

const Contact = () => {
  return (
    <Layout>
      <PageMeta title='Contact Us - Apoyphe' />
      <Navbar logoDark navRight={<NavRightOne />} />
      <div id='main_content'>
        <BreadCrumb breadCrumbTitle='Contact Us' pageName='Contact Us' />
        <ServiceFive/>
        <ContactFormMap/>
        <ContactInfo />
        <FooterOne />
      </div>
    </Layout>
  );
};

export default Contact;
