// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom'; // Import useParams
// import { casestudies } from './casestudieslist'; // Ensure you import your case studies list
// // import Portfolio from '../../pages/Portfolio/Portfolio';

// const CaseStudiesDetails = () => {
//   const { id } = useParams(); // Get the ID from the URL
//   const [selectedStudy, setSelectedStudy] = useState(null);

//   useEffect(() => {
//     // Find the selected study based on the ID from the URL
//     const study = casestudies.find((s) => s.id === parseInt(id));
//     setSelectedStudy(study);
//   }, [id]);

//   if (!selectedStudy) return <div>Loading...</div>; // Handle loading state

//   return (
//     <>

//     </>
//   );
// };

// export default CaseStudiesDetails;

import React from "react";
import Navbar from "../../layout/Header/Navbar";
import NavRightOne from "../../components/NavRight/NavRightOne";

import FooterOne from "../../layout/Footer/FooterOne";
import PageMeta from '../../components/PageMeta';

// import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';

const Casestudies1 = () => {
  return (
    <>
      <PageMeta title='Valuetext Cloud Migration from CtrlS to AWS -  Apoyphe ' />
      <Navbar logoDark navRight={<NavRightOne />} />


      <section id="hero" className="hero section light-background">
        <div className="container">
          <div className="row ">
            <div
              className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center"
              data-aos="zoom-out"
            >
              <h1>
              Valuetext Cloud Migration from<span> CtrlS to AWS</span>
              </h1>
              <p>
              Delivering Scalable and Secure Messaging Solutions
              </p>
           
            </div>
          </div>
        </div>
      </section>


      <main className="main">

        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <section id="blog-details" className="blog-details section">
                <div className="container">
                  <article className="article">
                    <div className="post-img">
                      <img
                        src="assets/img/blog/blog-1.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>

                    <br />
                    <br />
                    <h2 className="title">Introduction</h2>
                    {/* End meta top */}
                    <div className="content">
                      <p>
                        Valuetext, a leader in messaging solutions, faced the
                        challenge of scaling their services while maintaining
                        security and performance. Hosted on CtrlS data centers,
                        they sought greater flexibility and access to
                        cutting-edge cloud features. Apoyphe was selected to
                        migrate their services to AWS, providing the client with
                        a highly scalable, reliable, and secure infrastructure
                        that could accommodate future growth.
                      </p>


                      <br />
                      <br />
                      <h3>Challenges</h3>
                      <p>
                        Valuetext had a complex infrastructure that required
                        high availability to support millions of messages sent
                        daily. Their primary concerns included maintaining
                        uptime during the migration, ensuring data security, and
                        optimizing costs post-migration. Additionally, their
                        system’s architecture needed an overhaul to make use of
                        the agility and dynamic nature of AWS services. The
                        challenge was to migrate their workloads seamlessly
                        without disrupting operations, all while ensuring strict
                        adherence to security and compliance standards.
                      </p>
                      <img
                        src="assets/img/blog/blog-inside-post.jpg"
                        className="img-fluid"
                        alt=""
                      />
                      <h3>Solution</h3>
                      <p>
                        Apoyphe implemented a phased migration approach,
                        beginning with an in-depth assessment of Valuetext’s
                        existing environment. We mapped out all dependencies and
                        created a plan to transition the services in stages,
                        minimizing downtime. Key AWS services such as EC2, S3,
                        and RDS were leveraged to replace existing on-premises
                        infrastructure, providing better scalability and cost
                        efficiency.
                      </p>
                      <br />
                      <p>
                        We used AWS CloudFormation templates to replicate the
                        infrastructure, ensuring repeatability and consistency.
                        The use of Auto Scaling groups allowed Valuetext to
                        handle increased traffic during peak periods, while
                        reserved instances provided cost savings for
                        steady-state operations. Data migration was handled
                        securely through AWS Direct Connect to minimize latency
                        and ensure high-speed data transfer from CtrlS to AWS.
                      </p>

                      <br />
                      <br />

                      <h3>Outcomes</h3>
                      <p>
                        The migration was completed ahead of schedule, with no
                        major disruptions to Valuetext's operations. AWS's
                        scalability features allowed them to support more users
                        and handle peak workloads without performance issues.
                        Furthermore, the migration led to a significant
                        reduction in infrastructure costs, improved reliability,
                        and enhanced data security. Valuetext now enjoys the
                        flexibility to easily expand services globally, with AWS
                        providing a platform to support their growing user base.
                      </p>
                      <br />
                      <br />
                      <br />
                      <br />
                    </div>
                  </article>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>

      <FooterOne />
    </>
  );
};

export default Casestudies1;
