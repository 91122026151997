import React from 'react';
import PageMeta from '../../components/PageMeta';
import Layout from '../../layout';
import Navbar from '../../layout/Header/Navbar';
import HeroNine from './HeroNine';
import NavRightOne from '../../components/NavRight/NavRightOne';
import FooterOne from '../../layout/Footer/FooterOne';
import ApoypheAiS1 from '../../components/Services/ApoypheAiS1';
import ApoypheAiS2 from '../../components/Services/ApoypheAiS2';
import ApoypheAiS3 from '../../components/Features/ApoypheAiS3';

const ApoypheAI = () => {
  return (
    <Layout>
      <PageMeta title='Apoyphe AI ' />
      <Navbar logoDark navRight={<NavRightOne warningBtn />} />
      <div id='main_content'>
        <HeroNine />
        <ApoypheAiS1/>
        <ApoypheAiS2/>
        <ApoypheAiS3/>
        <FooterOne />
      </div>
    </Layout>
  );
};

export default ApoypheAI;
