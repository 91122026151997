import React, { Component } from "react";
import SectionTitle from "../SectionTitle/SectionTitle";

class ImageContent1 extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="image-content-area">
          <div className="container">
            <div className="d-flex justify-content-between">
              <div className="col-xl-5">
                <div className="tt-parallax__image tt-parallax__image--one">
                  <div className="shape-image">
                    <div
                      className="shape-wrapper wow fadeInLeft"
                      data-wow-dealy="0.3s"
                    >
                      <img
                        // className='wow fadeInDown'
                        data-wow-delay="ms"
                        src='media/home/Home-3.gif'
                        alt="banner 1"
                      />
                    </div>
                  </div>

                  {/* <div className='parallax-image'>
                    <div className='parallax-image-wrapper'>
                      <ul className='parallax-image-list'>
                        <li className='wow fadeInUp' data-wow-delay='0.3s'>
                          <img src='media/image-content/img_01.png' alt='' />
                        </li>
                        <li className='wow fadeInUp' data-wow-delay='0.3s'>
                          <img src='media/image-content/img_02.png' alt='' />
                        </li>
                        <li className='wow fadeInUp' data-wow-delay='0.5s'>
                          <img src='media/image-content/img_03.png' alt='' />
                        </li>
                        <li className='wow fadeInUp' data-wow-delay='0.5s'>
                          <img src='media/image-content/img_04.png' alt='' />
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="col-xl-6">
                <div className="image-content">
                  <SectionTitle
                    classOption="style-one"
                    firstTitle="Increase your business growth"
                    description=" Our cloud solutions are engineered for performance optimization and business scalability, with a focus on achieving high availability, low latency, and robust security protocols."
                    descriptionTwo=" We offer 24/7 technical support to ensure uninterrupted operations, proactive issue resolution, and disaster recovery planning to safeguard your mission-critical workloads Using cutting-edge cloud tools and frameworks,"
                    descriptionThree=" we help businesses stay competitive in an ever-evolving digital landscape."
                  />

                  {/* <h4 className="title wow fadeInUp" data-wow-delay="0.7s">
                    Discover the widgets
                  </h4> */}

                  {/* <div className="row">
                    <div className="col-xl-6">
                      <ul
                        className="tt-list wow fadeInUp"
                        data-wow-delay="0.9s"
                      >
                        <li className="list-item">
                          <i className="feather-check"></i>
                          <span className="list-text">Additional </span>
                        </li>

                        {/* <li className="list-item">
                          <i className="feather-check"></i>
                          <span className="list-text">Table</span>
                        </li> 
                        <li className="list-item">
                          <i className="feather-check"></i>
                          <span className="list-text">Cross</span>
                        </li>
                      </ul>
                    </div>

                    <div className="col-xl-6">
                      <ul
                        className="tt-list wow fadeInUp"
                        data-wow-delay="0.9s"
                      >
                        <li className="list-item">
                          <i className="feather-check"></i>
                          <span className="list-text">Content</span>
                        </li>

                        <li className="list-item">
                          <i className="feather-check"></i>
                          <span className="list-text">Design</span>
                        </li>
                      </ul>
                    </div>
                  </div> */}

                  {/* <a
                    href="about"
                    className="tt__btn btn-sqr btn-outline wow fadeInUp"
                    data-wow-delay="1.1s"
                  >
                    Read More
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default ImageContent1;
