import React, { Component } from "react";
import { Link } from "react-router-dom";

class ImageContentSix extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="image-content-area-five">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-5">
                <div className="tt-parallax__image tt-parallax__image--five">
                  <img src="media/about/about-vector.webp" alt="Shape " />
                </div>
              </div>

              <div className="col-md-6">
                <div className="image-content">
                  <div className="section-heading style-one ">
                    <h2
                      className="section-title wow fadeInUp"
                      data-wow-delay="0.3s"
                    >
                      Why Apoyphe?
                    </h2>

                    <p
                      className="description wow fadeInUp"
                      data-wow-delay="0.5s"
                    >
                      We are committed to providing IT services designed to accelerate digital transformation and enhance operational performance.
                      <br />
                       Backed by expertise, we focus on cloud migration, infrastructure management, and comprehensive IT solutions that keep your business adaptive and efficient.
                      <br />
                     
                    </p>
                  </div>

                  <h4 className="title wow fadeInUp" data-wow-delay="0.7s">
                     Uniqueness
                  </h4>

                  <div className="row">
                    <div className="col-md-12">
                      <ul
                        className="tt-list wow fadeInUp"
                        data-wow-delay="0.9s"
                      >
                        <li className="list-item">
                          <i className="feather-check"></i>
                          <span className="list-text">End-to-End Cloud Expertise</span>
                        </li>

                        {/* <li className="list-item">
                          <i className="feather-check"></i>
                          <span className="list-text">Innovation-Driven</span>
                        </li> */}
                        <li className="list-item">
                          <i className="feather-check"></i>
                          <span className="list-text">Security-Focused</span>
                        </li>
                      </ul>
                    </div>

                    {/* <div className="col-md-6">
                      <ul
                        className="tt-list wow fadeInUp"
                        data-wow-delay="0.9s"
                      >
                        <li className="list-item">
                          <i className="feather-check"></i>
                          <span className="list-text">Content</span>
                        </li>

                        <li className="list-item">
                          <i className="feather-check"></i>
                          <span className="list-text">UX Design</span>
                        </li>
                      </ul>
                    </div> */}
                  </div>

                  <Link
                    to="/cloud-services"
                    className="tt__btn btn-sqr btn-outline wow fadeInUp mt-5"
                    data-wow-delay="1.1s"
                  >
                    Visit Services
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default ImageContentSix;
