import React, { Component } from 'react';
import Isotope from 'isotope-layout/js/isotope';
import ImagesLoaded from 'imagesloaded/imagesloaded';
import { Link } from 'react-router-dom';

class Courses extends Component {
  state = {
    activeItem: '*',
  };
  componentDidMount() {
    var imgLoad = new ImagesLoaded('.tt-portfolio');

    imgLoad.on('progress', function (instance, image) {
      this.iso = new Isotope('.tt-portfolio', {
        itemSelector: '.tt-portfolio__item',
        layoutMode: 'masonry',
      });
    });
  }
  onFilterChange = (newFilter) => {
    this.setState({ activeItem: newFilter });
    if (this.iso === undefined) {
      this.iso = new Isotope('.tt-portfolio', {
        itemSelector: '.tt-portfolio__item',
        layoutMode: 'masonry',
      });
    }

    // this.iso.arrange({ filter: newFilter });

    if (newFilter === '*') {
      this.iso.arrange({ filter: `*` });
    } else {
      this.iso.arrange({ filter: `.${newFilter}` });
    }
  };
  onActive = (item) => (item === this.state.activeItem ? 'isActive' : '');
  render() {
    return (
      <React.Fragment>
        <section className='courses-section' id='case-studies-section'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-6'>
                <div className='section-heading style-one'>
                  <h2
                    className='section-title wow fadeInUp'
                    data-wow-delay='0.3s'
                  >
                    Our Case Studies
                    <br />
                  </h2>

                  <p className='description wow fadeInUp' data-wow-delay='0.5s'>
                    Find the Right Solution for the Growth of Your Business
                  </p>
                </div>
              </div>

            </div>

            <div className='tt-portfolio__wrapper style-grid'>
              <div className='tt-portfolio row'>
                <div className='grid-sizer'></div>

                <div
                  className={`tt-portfolio__item col-lg-4 col-md-6 col-sm-6 trending development`}
                >
                  <div className='tt-course'>
                    <div className='tt-course__post-wrapper'>
                      <div className='tt-course__post-thumbnail'>
                        <img src='media/case-studies/case-studies_img1.png' alt='case-studies1' />
                      </div>
                    </div>

                    <div className='tt-course__info'>
                      {/* <div className='tt-course__meta-wrap'>
                        <div className='tt-course__meta-cat'>
                          <a href='#//' className='tt-course__cate'>
                            Data Science
                          </a>
                        </div>

                        <div className='tt-course__rating'>
                          <i className='ei ei-icon_star'></i>
                          <span>24 Votes</span>
                        </div>
                      </div> */}

                      <h3 className='tt-course__title'>
                        Valuetext Cloud Migration from CtrlS to AWS
                      </h3>

                      <span className='tt-course__lessons'>
                        {/* <i className='ei ei-icon_book_alt'></i>36 Lessons */}
                        <p>Delivering Scalable and Secure Messaging Solutions</p>
                      </span>

                      <div className='tt-course__footer'>
                        <div className='tt-course__price-wrap'>
                          <h4 className='tt-course__price'>
                            <Link to='/case-studies-1'> <span className='old-price'>Read More</span></Link>
                          </h4>
                        </div>

                        <Link to='/case-studies-1' className='more-link'>
                          <i className='feather-arrow-right'></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={`tt-portfolio__item col-lg-4 col-md-6 col-sm-6 grid-item design featured`}
                >
                  <div className='tt-course'>
                    <div className='tt-course__post-wrapper'>
                      <div className='tt-course__post-thumbnail'>
                        <img src='media/case-studies/case-studies_img2.png' alt='portfolio ' />
                      </div>
                    </div>

                    <div className='tt-course__info'>
                      {/* <div className='tt-course__meta-wrap'>
                        <div className='tt-course__meta-cat'>
                          <a href='#/' className='tt-course__cate'>
                            Development
                          </a>
                        </div>

                        <div className='tt-course__rating'>
                          <i className='ei ei-icon_star'></i>
                          <span>30 Votes</span>
                        </div>
                      </div> */}

                      <h3 className='tt-course__title'>
                        Earnin’s Migration from GoDaddy cPanel and Digital Ocean to AWS
                      </h3>

                      <span className='tt-course__lessons'>
                        {/* <i className='ei ei-icon_book_alt'></i>42 Lessons */}
                        <p>Consolidating Infrastructure for Better Performance and Security</p>
                      </span>

                      <div className='tt-course__footer'>
                        <div className='tt-course__price-wrap'>
                          <h4 className='tt-course__price'>
                            <Link to="/case-studies-2"> <span className='old-price'>Read More</span></Link>
                          </h4>
                        </div>

                        <Link to='/case-studies-2' className='more-link'>
                          <i className='feather-arrow-right'></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={`tt-portfolio__item col-lg-4 col-md-6 col-sm-6 grid-item trending desing`}
                >
                  <div className='tt-course'>
                    <div className='tt-course__post-wrapper'>
                      <div className='tt-course__post-thumbnail'>
                        <img src='media/case-studies/case-studies_img3.png' alt='portfolio ' />
                      </div>
                    </div>

                    <div className='tt-course__info'>
                      {/* <div className='tt-course__meta-wrap'>
                        <div className='tt-course__meta-cat'>
                          <a href='#/' className='tt-course__cate'>
                            Art & Design
                          </a>
                        </div>

                        <div className='tt-course__rating'>
                          <i className='ei ei-icon_star'></i>
                          <span>36 Votes</span>
                        </div>
                      </div> */}

                      <h3 className='tt-course__title'>
                        Kaveri Seeds Migration from On-Premise Data Center to AWS
                      </h3>

                      <span className='tt-course__lessons'>
                        {/* <i className='ei ei-icon_book_alt'></i>58 Lessons */}
                        <p>Transforming On-Prem Infrastructure for Scalability and Security</p>
                      </span>

                      <div className='tt-course__footer'>
                        <div className='tt-course__price-wrap'>
                          <h4 className='tt-course__price'>
                            <Link to="/case-studies-3"><span className='old-price'>Read More</span></Link>
                          </h4>
                        </div>

                        <Link to='/case-studies-3' className='more-link'>
                          <i className='feather-arrow-right'></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={`tt-portfolio__item col-lg-4 col-md-6 col-sm-6 grid-item design development`}
                >
                  <div className='tt-course'>
                    <div className='tt-course__post-wrapper'>
                      <div className='tt-course__post-thumbnail'>
                        <img src='media/case-studies/case-studies_img4.png' alt='portfolio' />
                      </div>
                    </div>

                    <div className='tt-course__info'>
                      {/* <div className='tt-course__meta-wrap'>
                        <div className='tt-course__meta-cat'>
                          <a href='#/' className='tt-course__cate'>
                            UX Design
                          </a>
                        </div>

                        <div className='tt-course__rating'>
                          <i className='ei ei-icon_star'></i>
                          <span>40 Votes</span>
                        </div>
                      </div> */}

                      <h3 className='tt-course__title'>
                        SuccessKPI's Microsoft O365 Tenant Migration
                      </h3>

                      <span className='tt-course__lessons'>
                        {/* <i className='ei ei-icon_book_alt'></i>19 Lessons */}
                        <p>Seamless Consolidation for Improved Collaboration</p>
                      </span>

                      <div className='tt-course__footer'>
                        <div className='tt-course__price-wrap'>
                          <h4 className='tt-course__price'>
                            <Link to="/case-studies-4">  <span className='old-price'>Read More</span></Link>
                          </h4>
                        </div>

                        <Link to='/case-studies-4' className='more-link'>
                          <i className='feather-arrow-right'></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={`tt-portfolio__item col-lg-4 col-md-6 col-sm-6 grid-item trending`}
                >
                  <div className='tt-course'>
                    <div className='tt-course__post-wrapper'>
                      <div className='tt-course__post-thumbnail'>
                        <img src='media/case-studies/case-studies_img5.png' alt='portfolio ' />
                      </div>
                    </div>

                    <div className='tt-course__info'>
                      {/* <div className='tt-course__meta-wrap'>
                        <div className='tt-course__meta-cat'>
                          <a href='#/' className='tt-course__cate'>
                            Management
                          </a>
                        </div>

                        <div className='tt-course__rating'>
                          <i className='ei ei-icon_star'></i>
                          <span>27 Votes</span>
                        </div>
                      </div> */}

                      <h3 className='tt-course__title'>
                        Gaja Engineering Inventory Management Application
                      </h3>

                      <span className='tt-course__lessons'>
                        {/* <i className='ei ei-icon_book_alt'></i>27 Lessons */}
                        <p>Custom-Built Application for Efficient Inventory Management</p>
                      </span>

                      <div className='tt-course__footer'>
                        <div className='tt-course__price-wrap'>
                          <h4 className='tt-course__price'>
                            <Link to="/case-studies-5"> <span className='old-price'>Read More</span></Link>
                          </h4>
                        </div>

                        <Link to='/case-studies-5' className='more-link'>
                          <i className='feather-arrow-right'></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={`tt-portfolio__item col-lg-4 col-md-6 col-sm-6 grid-item trending`}
                >
                  <div className='tt-course'>
                    <div className='tt-course__post-wrapper'>
                      <div className='tt-course__post-thumbnail'>
                        <img src='media/case-studies/case-studies_img6.png' alt='portfolio ' />
                      </div>
                    </div>

                    <div className='tt-course__info'>
                      {/* <div className='tt-course__meta-wrap'>
                        <div className='tt-course__meta-cat'>
                          <a href='#/' className='tt-course__cate'>
                            Engineering
                          </a>
                        </div>

                        <div className='tt-course__rating'>
                          <i className='ei ei-icon_star'></i>
                          <span>32 Votes</span>
                        </div>
                      </div> */}

                      <h3 className='tt-course__title'>
                        SeedWorks PowerBI Analytics Dashboard Development
                      </h3>

                      <span className='tt-course__lessons'>
                        {/* <i className='ei ei-icon_book_alt'></i>65 Lessons */}
                        <p>Powerful Data Insights for Better Decision-Making</p>
                      </span>

                      <div className='tt-course__footer'>
                        <div className='tt-course__price-wrap'>
                          <h4 className='tt-course__price'>
                            <Link to="/case-studies-6">   <span className='old-price'>Read More</span></Link>
                          </h4>
                        </div>

                        <Link to='/case-studies-6' className='more-link'>
                          <i className='feather-arrow-right'></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>


              </div>

              {/* <div className='btn-container course-buttons text-center'>
                <a href='#/' className='tt__btn btn-outline btn-sqr'>
                  Browse More Courses
                </a>
              </div> */}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default Courses;




// import React from 'react';
// import { useHistory } from 'react-router-dom'; // Import useHistory hook
// import { casestudies } from './casestudieslist';
// // import PortfolioDetails from './PortfolioDetails';

// const Courses = () => {
//   // const [selectedStudy, setSelectedStudy] = useState(null);

//   // const onReadMore = (id) => {
//   //   const selected = casestudies.find((study) => study.id === id);
//   //   setSelectedStudy(selected);
//   // };

//   const history = useHistory(); // Hook to programmatically navigate

//   const handleReadMore = (id) => {
//     history.push(`/case-studies/${id}`); // Navigate to the portfolio details page
//   };

//   return (
//     <section className='courses-section' id="case-studies-section">
//       <div className='container'>
//         <div className='row align-items-center'>
//           <div className='col-lg-6'>
//             <div className='section-heading style-one'>
//               <h2 className='section-title wow fadeInUp' data-wow-delay='0.3s'>
//                 Our Case Studies
//                 <br />
//               </h2>
//               <p className='description wow fadeInUp' data-wow-delay='0.5s'>
//                 Find the Right Solution for the Growth of Your Business
//               </p>
//             </div>
//           </div>
//         </div>

//         <div className='tt-portfolio__wrapper style-grid'>
//           <div className='tt-portfolio row'>
//             <div className='grid-sizer'></div>

//             {casestudies.map((study) => (
//               <div className="tt-portfolio__item col-lg-4 col-md-6 col-sm-6" key={study.id}>
//                 <div className='tt-course'>
//                   <div className='tt-course__post-wrapper'>
//                     <div className='tt-course__post-thumbnail'>
//                       <img src={study.image} alt={study.alt} />
//                     </div>
//                   </div>
//                   <div className='tt-course__info'>
//                     <h3 className='tt-course__title'>{study.title}</h3>
//                     <span className='tt-course__lessons'>
//                       <p>{study.description}</p>
//                     </span>
//                     <div className='tt-course__footer'>
//                       <div className='tt-course__price-wrap'>
//                         <h4 className='tt-course__price'>
//                           <a href='/'  onClick={(e) => { e.preventDefault(); handleReadMore(study.id); }}>
//                             <span className='old-price'>{study.readMore}</span>
//                           </a>
//                         </h4>
//                       </div>
//                       <a href='/'  onClick={(e) => { e.preventDefault(); handleReadMore(study.id); }} className='more-link'>
//                         <i className='feather-arrow-right'></i>
//                       </a>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>

//         {/* {selectedStudy && <PortfolioDetails selectedStudy={selectedStudy} />} */}
//       </div>
//     </section>
//   );
// };

// export default Courses;
