import React, { Component } from "react";
import SectionTitle from "../SectionTitle/SectionTitle";

class ImageContent2 extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="image-content-area-two">
          <div className="container">
            <div className="d-flex justify-content-between">
              <div className="col-xl-6 tt-order-2">
                <div className="image-content">
                  <SectionTitle
                    classOption="style-one"
                    firstTitle="AI-Powered "
                    lastTitle="Cloud Solutions"
                    description="Our AI-driven cloud solutions are designed for intelligent performance optimization and business scalability, ensuring high availability, low latency, and advanced security measures."
                    descriptionTwo="We incorporate AI-powered monitoring and predictive analytics, providing 24/7 technical support to automate issue detection, accelerate resolution, and enhance disaster recovery for mission-critical workloads. By leveraging cutting-edge AI technologies and frameworks, we empower businesses to stay ahead in a rapidly evolving digital landscape, making data-driven decisions and automating complex processes with ease."
                    descriptionThree=""
                  />

                  {/* <ul className="tt-list style-two">
                    <li
                      className="list_item wow fadeInUp"
                      data-wow-delay="0.7s"
                    >
                      <i className="ei ei-icon_check"></i>

                      <div className="list-content">
                        <h3 className="list-text">Multipurpose Layout</h3>
                        <p>
                          The bee's knees cracking goal it's all gone to
                          <br /> pot old young delinquent.
                        </p>
                      </div>
                    </li>
                    <li
                      className="list_item wow fadeInUp"
                      data-wow-delay="0.7s"
                    >
                      <i className="ei ei-icon_check"></i>

                      <div className="list-content">
                        <h3 className="list-text">Application Design</h3>

                        <p>
                          The bee's knees cracking goal it's all gone to
                          <br /> pot old young delinquent.
                        </p>
                      </div>
                    </li>
                  </ul> */}

                  {/* <a
                    href="about"
                    className="tt__btn btn-sqr btn-outline wow fadeInUp"
                    data-wow-delay="0.9s"
                  >
                    Read More
                  </a> */}
                </div>
              </div>

              <div className="col-xl-5">
                <div className="tt-parallax__image tt-parallax__image--two">
                  <div className="shape-image">
                    <div className="shape-wrapper wow fadeInLeft">
                      <img
                        className='wow fadeInDown'
                        data-wow-delay="ms"
                        src="media/home/Home-4.gif"
                        alt="banner 1"
                      />
                    </div>
                  </div>

                  {/* <div className="parallax-image">
                    <div className="parallax-image-wrapper">
                      <ul className="parallax-image-list_two">
                        <li className="wow fadeInUp" data-wow-delay="0.3s">
                          <img
                            src="media/image-content/img_05.png"
                            alt="ultraland"
                          />
                        </li>
                        <li className="wow fadeInUp" data-wow-delay="0.5s">
                          <img
                            src="media/image-content/img_06.png"
                            alt="ultraland"
                          />
                        </li>
                        <li className="wow fadeInUp" data-wow-delay="0.7s">
                          <img
                            src="media/image-content/img_07.png"
                            alt="ultraland"
                          />
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default ImageContent2;
