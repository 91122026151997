import React from "react";
import SectionTitle from "../SectionTitle/SectionTitle";

export default function ServiceFour() {
  return (
    <div>
      <section className="service-four-area">
        <div className="container">
          <SectionTitle
            classOption="text-center"
            firstTitle="How We Help"
            // lastTitle="  The world’s largest"
            description="Our methodology encompasses a complete lifecycle approach to cloud service management, ensuring a smooth transition of your cloud architecture"
          />

          <div className="row">
            <div className="col-lg-6">
              <div
                className="tt-icon-box style-five wow fadeInUp"
                data-wow-delay="0.5s"
              >
                <div className="icon-container">
                  <img src="media/home/Evaculate.gif" alt="" />
                </div>

                <div className="box-content">
                  <h4 className="box-title">
                    <a href="service.html"  style={{ pointerEvents: 'none' }}>Evaluate</a>
                  </h4>

                  <p className="description">
                  Assess your needs, ensuring a seamless on-premise to cloud migration strategy and make a plan to evaluate the project.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div
                className="tt-icon-box style-five wow fadeInUp"
                data-wow-delay="0.5s"
              >
                <div className="icon-container">
                  <img src="media/home/Plan.gif" alt="" />
                </div>

                <div className="box-content">
                  <h4 className="box-title">
                    <a href="service.html"  style={{ pointerEvents: 'none' }}>Plan</a>
                  </h4>

                  <p className="description">
                  Strategize your business goals, creating a tech roadmap for sustained process for your business success.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div
                className="tt-icon-box style-five wow fadeInUp"
                data-wow-delay="0.7s"
              >
                <div className="icon-container">
                  <img src="media/home/Migrate.gif" alt="" />
                </div>

                <div className="box-content">
                  <h4 className="box-title">
                    <a href="service.html"  style={{ pointerEvents: 'none' }}>Migrate</a>
                  </h4>

                  <p className="description">
                  Execute flawless plans, achieving successful cloud implementation without disruption or hassle.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div
                className="tt-icon-box style-five wow fadeInUp"
                data-wow-delay="0.7s"
              >
                <div className="icon-container">
                  <img src="media/home/Optimize.gif" alt="" />
                </div>

                <div className="box-content">
                  <h4 className="box-title">
                    <a href="service.html"  style={{ pointerEvents: 'none' }}>Optimize</a>
                  </h4>

                  <p className="description">
                  Reduce costs by identifying and eliminating mismanaged resources, assets and cloud spending.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
