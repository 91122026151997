import React from "react";
import Navbar from "../../layout/Header/Navbar";
import NavRightOne from "../../components/NavRight/NavRightOne";

import FooterOne from "../../layout/Footer/FooterOne";
import PageMeta from "../../components/PageMeta";

const Casestudies5 = () => {
  return (
    <>
      <PageMeta title="Gaja Engineering Inventory Management Application Development -  Apoyphe " />
      <Navbar logoDark navRight={<NavRightOne />} />

      <section id="hero" className="hero section light-background">
        <div className="container">
          <div className="row ">
            <div
              className="col-lg-10 order-2 order-lg-1 d-flex flex-column justify-content-center"
              data-aos="zoom-out"
            >
              <h1>
                Gaja Engineering Inventory Management
                <span> Application Development</span>
              </h1>
              <p>Custom-Built Application for Efficient Inventory Management</p>
            </div>
          </div>
        </div>
      </section>

      <main className="main">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <section id="blog-details" className="blog-details section">
                <div className="container">
                  <article className="article">
                    <div className="post-img">
                      <img
                        src="assets/img/blog/blog-1.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>

                    <br />
                    <br />
                    <h2 className="title">Introduction</h2>
                    {/* End meta top */}
                    <div className="content">
                      <p>
                        Gaja Engineering needed an inventory
                        management system to streamline their operations. They
                        required a custom-built application that could manage
                         materials, track stock levels, and generate
                        real-time reports. Apoyphe was tasked with developing
                        this robust application to meet their specific needs.
                      </p>

                      <br />
                      <br />
                      <h3>Challenges</h3>
                      <p>
                        The industry deals with complex inventory
                        management, including bulk materials that need to be
                        tracked across various sites. Gaja Engineering needed an
                        intuitive, user-friendly system that could handle high
                        volumes of data and provide real-time updates across
                        multiple locations.
                      </p>
                      <img
                        src="assets/img/blog/blog-inside-post.jpg"
                        className="img-fluid"
                        alt=""
                      />
                      <h3>Solution</h3>
                      <p>
                        Apoyphe developed a cloud-based inventory management
                        system using a microservices architecture, ensuring
                        scalability and flexibility. The application was built
                        on AWS using services such as Lambda for serverless
                        computing, RDS for database management, and S3 for
                        storing documents and reports.
                      </p>
                      <br />
                      <p>
                        A user-friendly interface was designed, ensuring that
                        on-site managers could easily update inventory levels
                        and generate reports in real-time. We also implemented a
                        mobile version of the application to allow remote access
                        for field workers.
                      </p>

                      <br />
                      <br />

                      <h3>Outcomes</h3>
                      <p>
                        The application streamlined Gaja Engineering’s inventory
                        management, improving efficiency across multiple sites.
                        With real-time reporting, the company was able to reduce
                        material shortages and overstocking, leading to cost
                        savings. The scalable architecture also allows for
                        future growth, accommodating Gaja Engineering’s expanding
                        operations.
                      </p>
                      <br />
                      <br />
                      <br />
                      <br />
                    </div>
                  </article>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>

      <FooterOne />
    </>
  );
};

export default Casestudies5;
