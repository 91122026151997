import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../SectionTitle/SectionTitle';

class ServiceThree extends Component {
  render() {
    return (
      <React.Fragment>
        <div className='service-three area'>
          <div className='container'>
            <SectionTitle
              firstTitle='Redefining '
              lastTitle='Innovative IT Solutions'
              description="At Apoyphe, we are dedicated to delivering innovative IT services that drive digital transformation and operational excellence."
            />
            <div className='row'>
              <div className='col-md-4'>
                <div
                  className='tt-icon-box style-three wow fadeInUp'
                  data-wow-delay='0.7s'
                >
                  <div className='icon-container'>
                    <img
                      src='media\about\about-icon1.png'
                      alt='Direct Access'
                    />
                  </div>

                  <div className='box-content'>
                    <h4 className='box-title'>
                      <Link to='/service'  style={{ pointerEvents: 'none' }}>Expert Solutions</Link>
                    </h4>

                    <p className='description'>
                      With decades of experience, Apoyphe specializes in cloud migration, infrastructure management, and IT solutions that ensure your business stays agile, efficient, and secure.
                    </p>
                    {/* <Link to='/service' className='tt-more-link'>
                      Read More<i className='feather-arrow-right'></i>
                    </Link> */}
                  </div>
                </div>
              </div>

              <div className='col-md-4'>
                <div
                  className='tt-icon-box style-three wow fadeInUp'
                  data-wow-delay='0.9s'
                >
                  <div className='icon-container color__two'>
                    <img src='media\about\about-icon2.png' alt='Push Notification' />
                  </div>

                  <div className='box-content'>
                    <h4 className='box-title'>
                      <Link to='service'  style={{ pointerEvents: 'none' }}>Cloud Transformation</Link>
                    </h4>

                    <p className='description'>
                      Our team excels in transforming your on-premise infrastructure into a flexible, secure, and scalable cloud environment tailored to meet your unique business needs.
                    </p>

                    {/* <Link to='/service' className='tt-more-link'>
                      Read More<i className='feather-arrow-right'></i>
                    </Link> */}
                  </div>
                </div>
              </div>

              <div className='col-md-4'>
                <div
                  className='tt-icon-box style-three wow fadeInUp'
                  data-wow-delay='1.1s'
                >
                  <div className='icon-container color__three'>
                    <img
                      src='media\about\about-icon3.png'
                      alt='Mobile Capability'
                    />
                  </div>

                  <div className='box-content'>
                    <h4 className='box-title'>
                      <Link to='service'  style={{ pointerEvents: 'none' }}>Managed IT Services</Link>
                    </h4>

                    <p className='description'>
                    We offer managed services to ensure the reliability and performance of your IT infrastructure, so you can focus on what matters most—growing your business.
                    </p>

                    {/* <Link to='/service' className='tt-more-link'>
                      Read More<i className='feather-arrow-right'></i>
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ServiceThree;
