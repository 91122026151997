import React from 'react';
import { Link } from 'react-router-dom';

const ContactInfo = () => {
  return (
    <React.Fragment>
      <section className='contact-info-section'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-12'>
              <div className='contact-info-wrap bg-wrap'>
                <div className='row no-gutters'>
                  <div className='col-md-4'>
                    <div className='tt-contact-info'>
                      <div className='icon-container'>
                        <img
                          src='media\contact_img\phone.png' width={70}
                          alt='+(426) 276 442 32'
                        />
                      </div>

                      <div className='box-content'>
                        <h4 className='box-title'>
                          <Link 
                            rel='noopener noreferrer'
                          >
                           +91 40357 19525
                          </Link>
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-4'>
                    <div className='tt-contact-info'>
                      <div className='icon-container'>
                        <img 
                          src='media/contact_img/email.png'  width={70}
                          alt='info@ultraland.com'
                        />
                      </div>

                      <div className='box-content'>
                        <h4 className='box-title'>
                          <a
                            href='mailto:info@ultraland.com'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            info@apoyphe.com
                          </a>
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-4'>
                    <div className='tt-contact-info'>
                      <div className='icon-container'>
                        <img
                         src='media\contact_img\loaction.png' width={60}
                          alt='23 Dhanmondi, Dhaka'
                        />
                      </div>

                      <div className='box-content'>
                        <h4 className='box-title'>Hyderabad, Telangana</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ContactInfo;
