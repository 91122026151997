import React from "react";
import Navbar from "../../layout/Header/Navbar";
import NavRightOne from "../../components/NavRight/NavRightOne";

import FooterOne from "../../layout/Footer/FooterOne";
import PageMeta from '../../components/PageMeta';

const Casestudies2 = () => {
  return (
    <>
       <PageMeta title=' Earnin’s Cloud Migration from GoDaddy  cPanel and Digital Ocean to AWS -  Apoyphe ' />
      <Navbar logoDark navRight={<NavRightOne />} />

      <section id="hero" className="hero section light-background">
        <div className="container">
          <div className="row ">
            <div
              className="col-lg-10 order-2 order-lg-1 d-flex flex-column justify-content-center"
              data-aos="zoom-out"
            >
              <h1>
                Earnin’s Cloud Migration from GoDaddy
                <span> cPanel and Digital Ocean to AWS</span>
              </h1>
              <p>
                Consolidating Infrastructure for Better Performance and Security
              </p>
            </div>
          </div>
        </div>
      </section>

      <main className="main">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <section id="blog-details" className="blog-details section">
                <div className="container">
                  <article className="article">
                    <div className="post-img">
                      <img
                        src="assets/img/blog/blog-1.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>

                    <br />
                    <br />
                    <h2 className="title">Introduction</h2>
                    {/* End meta top */}
                    <div className="content">
                      <p>
                        Earnin, a financial services company, was running two
                        separate applications on GoDaddy cPanel and Digital
                        Ocean. As their customer base grew, they found it
                        increasingly difficult to manage disparate
                        infrastructures. They sought a unified platform that
                        could handle both applications with greater security,
                        scalability, and operational efficiency. Apoyphe was
                        tasked with migrating both applications to AWS, where
                        they could benefit from a consolidated infrastructure.
                      </p>

                      <br />
                      <br />
                      <h3>Challenges</h3>
                      <p>
                        The primary challenge for Earnin was the need to migrate
                        two critical applications from entirely different
                        platforms without causing downtime. One application was
                        hosted on GoDaddy cPanel, and the other on Digital
                        Ocean. Both needed to be migrated with minimal impact on
                        the end users. Furthermore, Earnin required a robust
                        infrastructure with optimized security, especially since
                        they were handling sensitive financial data.
                      </p>
                      <img
                        src="assets/img/blog/blog-inside-post.jpg"
                        className="img-fluid"
                        alt=""
                      />
                      <h3>Solution</h3>
                      <p>
                        Apoyphe conducted a thorough analysis of both platforms
                        to identify their architecture, data flow, and
                        dependencies. For the GoDaddy cPanel application, we
                        used AWS Elastic Beanstalk, which allowed us to
                        seamlessly transition the app with minimal code changes.
                        The Digital Ocean-hosted application was migrated using
                        AWS EC2 instances with EBS for persistent storage.
                      </p>
                      <br />
                      <p>
                        Data migration for both platforms was handled via AWS
                        DataSync, ensuring that all data was securely
                        transferred to AWS S3 for storage and Glacier for
                        archival purposes. Apoyphe also implemented AWS IAM for
                        role-based access controls and AWS Shield for DDoS
                        protection, ensuring that Earnin’s infrastructure
                        remained secure.
                      </p>

                      <br />
                      <br />

                      <h3>Outcomes</h3>
                      <p>
                        Earnin saw an immediate improvement in application
                        performance post-migration, along with reduced
                        infrastructure management complexity. The unified AWS
                        environment allowed them to streamline operations,
                        improve security, and better serve their customers.
                        Operational costs were reduced, and Earnin’s IT team was
                        freed from managing multiple platforms, enabling them to
                        focus on enhancing customer-facing features.
                      </p>
                      <br />
                      <br />
                      <br />
                      <br />
                    </div>
                  </article>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>

      <FooterOne />
    </>
  );
};

export default Casestudies2;
