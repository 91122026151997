import React from 'react';
// import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Link } from 'react-router-dom';

const ErrorScreen = () => {
  return (
    <>
      <section className='error_page'>
        <div className='container pr'>
          <div className='error_page_wrapper text-center'>
            <div className='error-page-content'>
              <div className='error-image'>
                <img src='https://img.freepik.com/free-vector/internet-network-warning-404-error-page-file-found-web-page-internet-error-page-issue-found-network-404-error-present-by-man-sleep-display_1150-55450.jpg?t=st=1727783845~exp=1727787445~hmac=1607fc1c6e370038ae653322fa5bf6c937648de909dd8d67525a5dbb88a1bd7e&w=900' width={400} alt='UltraLand' />
              </div>

              <div className='error-info'>
                <h2 className='error-title'>Error 404</h2>

                <p className='lead'>
                  We can’t seem to find the page you’re looking for
                </p>

                <Link to='/' className='tt__btn error_btn'>
                  <i className='feather-arrow-left'></i>
                  Go Back to Home
                </Link>
              </div>

              <ul className='error-animate-element animate-element'>
                {/* <li className='layer'>
                  <img src='media/error/element1.png' alt='UltraLand' />
                </li> */}
                {/* <li className='layer'>
                  <img src='media/error/element2.png' alt='UltraLand' />
                </li> */}
                {/* <li className='layer'>
                  <img src='media/error/element3.png' alt='UltraLand' />
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ErrorScreen;
