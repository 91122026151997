import React from "react";
import Navbar from "../../layout/Header/Navbar";
import NavRightOne from "../../components/NavRight/NavRightOne";

import FooterOne from "../../layout/Footer/FooterOne";
import PageMeta from "../../components/PageMeta";
const Casestudies3 = () => {
  return (
    <>
      <PageMeta title=" Kaveri Seeds Migration from On-Premise Data Center to AWS -  Apoyphe " />
      <Navbar logoDark navRight={<NavRightOne />} />

      <section id="hero" className="hero section light-background">
        <div className="container">
          <div className="row ">
            <div
              className="col-lg-10 order-2 order-lg-1 d-flex flex-column justify-content-center"
              data-aos="zoom-out"
            >
              <h1>
                Kaveri Seeds Migration from On-Premise
                <span> Data Center to AWS</span>
              </h1>
              <p>
                Transforming On-Prem Infrastructure for Scalability and Security
              </p>
            </div>
          </div>
        </div>
      </section>

      <main className="main">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <section id="blog-details" className="blog-details section">
                <div className="container">
                  <article className="article">
                    <div className="post-img">
                      <img
                        src="assets/img/blog/blog-1.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>

                    <br />
                    <br />
                    <h2 className="title">Introduction</h2>
                    {/* End meta top */}
                    <div className="content">
                      <p>
                        Kaveri Seeds, one of the largest agriculture companies
                        in India, relied heavily on their on-premise data center
                        for critical business applications. However, as the
                        company expanded, the limitations of their physical data
                        center became apparent. Kaveri Seeds required a more
                        scalable and secure infrastructure that could grow with
                        the company’s increasing demands. Apoyphe was chosen to
                        migrate their on-premise data center to AWS,
                        transforming their infrastructure to a cloud-native
                        environment.
                      </p>

                      <br />
                      <br />
                      <h3>Challenges</h3>
                      <p>
                        Kaveri Seeds faced several challenges with their
                        on-premise data center, including limited scalability,
                        high operational costs, and increased vulnerability to
                        security risks. The company needed to ensure that their
                        mission-critical applications remained available
                        throughout the migration. Additionally, there was a need
                        to improve the overall security of their infrastructure,
                        especially in protecting sensitive agricultural research
                        data.
                      </p>
                      <img
                        src="assets/img/blog/blog-inside-post.jpg"
                        className="img-fluid"
                        alt=""
                      />
                      <h3>Solution</h3>
                      <p>
                        Apoyphe devised a migration strategy that focused on a
                        lift-and-shift approach, moving their entire data center
                        to AWS while making incremental improvements to the
                        architecture. We utilized AWS EC2, S3, and RDS to host
                        their applications and store their vast data pools.
                        Apoyphe also deployed AWS WAF (Web Application Firewall)
                        and AWS Shield to safeguard the company from potential
                        cyber threats.
                      </p>
                      <br />
                      <p>
                        To further enhance security, we implemented AWS
                        CloudTrail for governance and compliance tracking, and
                        AWS Key Management Service (KMS) for encrypting
                        sensitive data. Apoyphe also introduced automated
                        backups using AWS Backup, ensuring that Kaveri Seeds’
                        data was safe and recoverable in case of an outage.
                      </p>

                      <br />
                      <br />

                      <h3>Outcomes</h3>
                      <p>
                        The migration resulted in a 30% reduction in operational
                        costs and an improvement in system performance. Kaveri
                        Seeds now has the scalability to support its growing
                        operations, while the enhanced security measures ensure
                        the protection of their valuable data. With AWS, they
                        can scale resources up or down as needed, ensuring
                        optimal efficiency.
                      </p>
                      <br />
                      <br />
                      <br />
                      <br />
                    </div>
                  </article>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>

      <FooterOne />
    </>
  );
};

export default Casestudies3;
