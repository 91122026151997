import React from 'react';
// import CounterTwo from '../../components/Counter/CounterTwo';
// import FeatureSlide from '../../components/Features/FeatureSlide';
// import FooterTwo from '../../layout/Footer/FooterTwo';
import HeroFour from './HeroFour';
// import ImageContentFour from '../../components/ImageContent/ImageContentFour';
// import IntroTwo from '../../components/IntroVideo/IntroTwo';
// import NewsLetterThree from '../../components/NewsLetter/NewsLetterThree';
import PageMeta from '../../components/PageMeta';
// import ServiceFour from '../../components/Services/ServiceFour';
// import SkillOne from '../../components/Skills/SkillOne';
// import TeamOne from '../../components/Team/TeamOne';
// import TestimonialThree from '../../components/Testimonial/TestimonialThree';
// import NavNumber from '../../components/NavRight/NavNumber';
import Layout from '../../layout';
import Navbar from '../../layout/Header/Navbar';
// import ImageContentFive from '../../components/ImageContent/ImageContentFive';

import NavRightOne from '../../components/NavRight/NavRightOne';
import SoftwareServiceS1 from '../../components/Services/SoftwareServiceS1';
// import SoftwareServiceS2 from '../../components/Services/SoftwareServiceS2';
// import SoftwareServiceS3 from '../../components/Services/SoftwareServiceS3';
// import SoftwareServiceS4 from '../../components/Services/SoftwareServiceS4';
// import SoftwareServiceS5 from '../../components/Services/SoftwareServiceS5';
import FooterOne from '../../layout/Footer/FooterOne';

const SoftwareServices = () => {
  return (
    <Layout>
      <PageMeta title='Software Services - Apoyphe' />
      <Navbar navRight={<NavRightOne warningBtn />} />
      <div id='main_content'>
        <HeroFour />
        <SoftwareServiceS1/>
        {/* <SoftwareServiceS2/> */}
        {/* <SoftwareServiceS3/> */}
        {/* <SoftwareServiceS4/> */}
        {/* <SoftwareServiceS5/> */}
        {/* <ServiceFour /> */}
        {/* <ImageContentFive /> */}
        {/* <ImageContentFour /> */}
        {/* <IntroTwo /> */}
        {/* <SkillOne /> */}
        {/* <NewsLetterThree /> */}
        {/* <FeatureSlide /> */}
        {/* <CounterTwo /> */}
        {/* <TeamOne /> */}
        {/* <TestimonialThree /> */}
        <FooterOne />
      </div>
    </Layout>
  );
};

export default SoftwareServices;
