import React, { useState } from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../SectionTitle/SectionTitle";

const CloudOperationS1 = () => {
  const [selectedSection, setSelectedSection] = useState("CloudOps");

  const handleSectionClick = (section) => {
    setSelectedSection(section);
  };

  return (
    <React.Fragment>
      <section className="feature-tabs-three">
        <div className="container-fluid">
          <SectionTitle
            firstTitle="Managing cloud environment"
            lastTitle=""
            description="Enhancing your cloud environment with efficient operations management, ensuring performance, security, and continuous improvement."
            classOption="text-center"
          />
          <div
            className="tt-feature-tab-two wow fadeInUp"
            data-wow-delay="0.7s"
          >
            <nav>
              <div
                className="nav d-flex justify-content-center nav-pills tt-tabs-navigation feature-tab-two-nav"
                id="nav-tab"
                role="tablist"
              >
                <a
                  className={`nav-link tab-nav-item feature-tab-two-nav__item active`}
                  id="nav-home-tab"
                  data-toggle="tab"
                  href="#CloudOps"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected={selectedSection === "CloudOps"}
                  onClick={() => handleSectionClick("CloudOps")}
                >
                  <div className="tab-icon">
                    {/* <img src="media/feature/tab1.svg" alt="tab" /> */}
                    <i class="bi bi-cloud-check-fill"></i>
                  </div>
                  <div className="nav_content">
                    <h4 className="feature-tab-two-nav__title">CloudOps</h4>
                  </div>
                </a>

                <a
                  className="nav-link tab-nav-item feature-tab-two-nav__item"
                  id="nav-profile-tab"
                  data-toggle="tab"
                  href="#DevOps"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                  onClick={() => handleSectionClick("DevOps")}
                >
                  <div className="tab-icon">
                    {/* <img src="media/feature/tab2.svg" alt="tab" /> */}
                    <i class="bi bi-infinity fw-4"></i>
                  </div>

                  <div className="nav_content">
                    <h4 className="feature-tab-two-nav__title">DevOps</h4>
                  </div>
                </a>

                <a
                  className="nav-link tab-nav-item feature-tab-two-nav__item"
                  id="nav-profile-tab"
                  data-toggle="tab"
                  href="#FinOps"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                  onClick={() => handleSectionClick("FinOps")}
                >
                  <div className="tab-icon">
                    {/* <img src="media/feature/tab2.svg" alt="tab" /> */}
                    <i class="bi bi-currency-exchange"></i>
                  </div>

                  <div className="nav_content">
                    <h4 className="feature-tab-two-nav__title">FinOps</h4>
                  </div>
                </a>

                <a
                  className="nav-link disabled tab-nav-item feature-tab-two-nav__item"
                  id="nav-profile-tab"
                  data-toggle="tab"
                  href="#SecOps"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                  onClick={() => handleSectionClick(" SecOps ")}
                >
                  <div className="tab-icon">
                    {/* <img src="media/feature/tab2.svg" alt="tab" /> */}
                    <i class="bi bi-shield-fill-check"></i>
                  </div>

                  <div className="nav_content">
                    <h4 className="feature-tab-two-nav__title">SecOps</h4>
                  </div>
                </a>
              </div>
            </nav>

            <div>
              {selectedSection === "CloudOps" && (
                <div
                  className="mt-4 tab-content tt-tabs-content feature-tab-two"
                  id="nav-tabContent"
                >
                  <section className="image-content-area agriculture-section">
                    <div className="container">
                      <div className="row">
                        <div className="col-xl-7">
                          <div className="tt-parallax__image tt-parallax__image--one">
                            <div className="shape-image">
                              <div
                                className="shape-wrapper wow fadeInLeft"
                                data-wow-dealy="0.3s"
                              >
                                <img src="media\services\cloud_oprations\cloud-operation-vector1.jpg" alt="securityoperation" />
                              </div>
                            </div>

                            {/* <div className="parallax-image">
                              <div className="parallax-image-wrapper">
                                <ul className="parallax-image-list">
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.3s"
                                  >
                                    <img
                                      src="media/image-content/img_01.png"
                                      alt=""
                                    />
                                  </li>
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.3s"
                                  >
                                    <img
                                      src="media/image-content/img_02.png"
                                      alt=""
                                    />
                                  </li>
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.5s"
                                  >
                                    <img
                                      src="media/image-content/img_03.png"
                                      alt=""
                                    />
                                  </li>
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.5s"
                                  >
                                    <img
                                      src="media/image-content/img_04.png"
                                      alt=""
                                    />
                                  </li>
                                </ul>
                              </div>
                            </div> */}
                          </div>
                        </div>

                        <div className="col-xl-5">
                          <div className="image-content">
                            <SectionTitle
                              classOption="style-one"
                              firstTitle="Enhance Operational Efficiency"
                              description="Apoyphe’s Cloud Ops services are crafted to optimize your cloud infrastructure, ensuring seamless operations "
                              descriptionTwo="ensuring seamless operations and maximizing resource utilization while maintaining top-tier performance "
                              descriptionThree="and reliability."
                            />

                            {/* <h4
                              className="title wow fadeInUp"
                              data-wow-delay="0.7s"
                            >
                              Discover the widgets
                            </h4> */}

                            {/* <a
                              href="about"
                              className="tt__btn btn-sqr btn-outline wow fadeInUp"
                              data-wow-delay="1.1s"
                            >
                              Read More
                            </a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <div className="service-three area">
                    <div className="container">
                      <SectionTitle
                        firstTitle="Streamlined Management"
                        lastTitle=""
                        description="Our comprehensive cloud management solutions simplify your operations, enabling your team to focus on core business functions while we handle the complexities of your cloud environment."
                      />
                      <div className="row">
                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.7s"
                          >
                            <div className="icon-container">
                              <img
                                src="media/services/cloud_oprations/cloud-operation-icon1.png"
                                alt="Direct Access"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="/service" style={{pointerEvents:'none'}}>Proactive Monitoring</Link>
                              </h4>

                              <p className="description">
                                We implement advanced monitoring tools to detect and resolve issues before they impact your business operations, ensuring high availability and performance.
                              </p>
                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.9s"
                          >
                            <div className="icon-container color__two">
                              <img
                                src="media/services/cloud_oprations/cloud-operation-icon2.png"
                                alt="Push Notification"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Automated Processes</Link>
                              </h4>

                              <p className="description">
                                Our automation strategies streamline workflows and reduce manual intervention, enhancing productivity and minimizing the potential for human error in your cloud operations.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media/services/cloud_oprations/cloud-operation-icon3.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Resource Allocation</Link>
                              </h4>

                              <p className="description">
                                We optimize resource allocation in your cloud environment, ensuring that your applications run efficiently and effectively, without over-provisioning or under-utilizing resources.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="service-three area">
                    <div className="container">
                      <SectionTitle
                        firstTitle="Seamless Integration"
                        lastTitle=""
                        description="Apoyphe’s Cloud Ops facilitates easy integration of cloud services with your existing infrastructure, ensuring a cohesive and adaptable technology environment for your organization."
                      />
                      <div className="row">
                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.7s"
                          >
                            <div className="icon-container">
                              <img
                                src="media\services\cloud_oprations\cloud-operation-icon4.png"
                                alt="Direct Access"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="/service" style={{pointerEvents:'none'}}>Custom Solutions</Link>
                              </h4>

                              <p className="description">
                                We design tailored cloud operations solutions to fit your unique business requirements, enhancing overall efficiency and effectiveness.
                              </p>
                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.9s"
                          >
                            <div className="icon-container color__two">
                              <img
                                src="media/services/cloud_oprations/cloud-operation-icon5.png"
                                alt="Push Notification"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Cost Management</Link>
                              </h4>

                              <p className="description">
                                We focus on minimizing costs by identifying and eliminating unnecessary resources while ensuring you maintain operational excellence.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media/services/cloud_oprations/cloud-operation-icon6.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Performance Optimization</Link>
                              </h4>

                              <p className="description">
                                We continuously analyze and optimize cloud performance, ensuring your applications run smoothly and meet business demands without delays.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media/services/cloud_oprations/cloud-operation-icon7.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Security Enhancements</Link>
                              </h4>

                              <p className="description">
                                We integrate robust security measures into your cloud operations, protecting your data and ensuring compliance with industry standards.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media/services/cloud_oprations/cloud-operation-icon8.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Scalability Options</Link>
                              </h4>

                              <p className="description">
                                Our Cloud Ops services are designed to grow with your business, providing the flexibility to adapt to changing operational needs.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media/services/cloud_oprations/cloud-operation-icon9.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Expert Support</Link>
                              </h4>

                              <p className="description">
                                We offer dedicated 24/7 support from cloud experts, ensuring your operations are running smoothly and any issues are resolved promptly.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {selectedSection === "DevOps" && (
                <div
                  className="mt-4 tab-content tt-tabs-content feature-tab-two"
                  id="nav-tabContent"
                >
                  <section className="image-content-area agriculture-section">
                    <div className="container">
                      <div className="row">
                        <div className="col-xl-6">
                          <div className="image-content">
                            <SectionTitle
                              classOption="style-one"
                              firstTitle="Accelerate "
                              lastTitle="Software Delivery"
                              description="Apoyphe’s DevOps services empower your organization to speed  development and operations up software development and "
                              descriptionTwo=" deployment cycles while enhancing collaboration between"
                              descriptionThree="teams for optimal efficiency."
                            />

                            {/* <h4
                              className="title wow fadeInUp"
                              data-wow-delay="0.7s"
                            >
                              Discover the widgets
                            </h4> */}

                            {/* <a
                              href="about"
                              className="tt__btn btn-sqr btn-outline wow fadeInUp"
                              data-wow-delay="1.1s"
                            >
                              Read More
                            </a> */}
                          </div>
                        </div>

                        <div className="col-xl-6">
                          <div className="tt-parallax__image tt-parallax__image--one">
                            <div className="shape-image">
                              <div
                                className="shape-wrapper wow fadeInLeft"
                                data-wow-dealy="0.3s"
                              >
                                <img src="media\services\cloud_oprations\cloud-operation-devops-vector.png" alt="devops" />
                              </div>
                            </div>

                            {/* <div className="parallax-image">
                              <div className="parallax-image-wrapper">
                                <ul className="parallax-image-list">
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.3s"
                                  >
                                    <img
                                      src="media/image-content/img_01.png"
                                      alt=""
                                    />
                                  </li>
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.3s"
                                  >
                                    <img
                                      src="media/image-content/img_02.png"
                                      alt=""
                                    />
                                  </li>
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.5s"
                                  >
                                    <img
                                      src="media/image-content/img_03.png"
                                      alt=""
                                    />
                                  </li>
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.5s"
                                  >
                                    <img
                                      src="media/image-content/img_04.png"
                                      alt=""
                                    />
                                  </li>
                                </ul>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <div className="service-three area">
                    <div className="container">
                      <SectionTitle
                        firstTitle="Continuous Improvement"
                        lastTitle=""
                        description="Our DevOps approach emphasizes continuous improvement in processes, ensuring that your development life cycle is agile and adaptable to meet evolving business needs and technological advancements."
                      />
                      <div className="row">
                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.7s"
                          >
                            <div className="icon-container">
                              <img
                                src="media\services\cloud_oprations\devops-icon1.png"
                                alt="Direct Access"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="/service" style={{pointerEvents:'none'}}>Integrated Tools</Link>
                              </h4>

                              <p className="description">
                                We utilize leading DevOps tools like Jenkins, Docker, and Kubernetes to automate workflows and enhance collaboration between teams, ensuring a smooth software delivery process.
                              </p>
                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.9s"
                          >
                            <div className="icon-container color__two">
                              <img
                                src="media\services\cloud_oprations\devops-icon2.png"
                                alt="Push Notification"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Version Control</Link>
                              </h4>

                              <p className="description">
                                Implementing Git for version control allows for seamless tracking of changes, collaboration among developers, and rapid iteration without compromising code integrity.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media\services\cloud_oprations\devops-icon3.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Monitoring Solutions</Link>
                              </h4>

                              <p className="description">
                                We incorporate tools like Prometheus and Grafana to provide real-time monitoring and analytics, enabling proactive issue resolution and enhancing system performance.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="service-three area">
                    <div className="container">
                      <SectionTitle
                        firstTitle="Faster "
                        lastTitle="Team Collaboration"
                        description="Our DevOps strategies foster a culture of collaboration and communication between teams, breaking down silos and encouraging a shared responsibility for project success."
                      />
                      <div className="row">
                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.7s"
                          >
                            <div className="icon-container">
                              <img
                                src="media\services\cloud_oprations\devops-icon4.png"
                                alt="Direct Access"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="/service" style={{pointerEvents:'none'}}>Infrastructure as Code</Link>
                              </h4>

                              <p className="description">
                                We leverage tools such as Terraform to manage your infrastructure through code, enabling automation and consistency across environments.
                              </p>
                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.9s"
                          >
                            <div className="icon-container color__two">
                              <img
                                src="media/services/cloud_oprations/devops-icon5.png"
                                alt="Push Notification"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Automated Testing</Link>
                              </h4>

                              <p className="description">
                                Our integration of automated testing frameworks ensures that your applications are rigorously tested throughout the development cycle.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media\services\cloud_oprations\devops-icon6.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Rapid Deployment</Link>
                              </h4>

                              <p className="description">
                                Utilizing CI/CD pipelines, we streamline your deployment process, allowing for frequent and reliable releases with minimal downtime.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media\services\cloud_oprations\devops-icon7.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Scalability Features</Link>
                              </h4>

                              <p className="description">
                                Our DevOps practices enable your applications to scale effortlessly, ensuring that performance remains consistent during high-demand periods.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media\services\cloud_oprations\devops-icon8.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Security Integration</Link>
                              </h4>

                              <p className="description">
                                We incorporate DevSecOps principles to integrate security throughout the development process, ensuring that your applications are secure from inception.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media\services\cloud_oprations\devops-icon9.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Expert Guidance</Link>
                              </h4>

                              <p className="description">
                                Our experienced DevOps team provides ongoing support and consultation, helping you to refine your processes and achieve your business objectives effectively.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {selectedSection === "FinOps" && (
                <div
                  className="mt-4 tab-content tt-tabs-content feature-tab-two"
                  id="nav-tabContent"
                >
                  <section className="image-content-area agriculture-section">
                    <div className="container">
                      <div className="row">
                        <div className="col-xl-7">
                          <div className="tt-parallax__image tt-parallax__image--one">
                            <div className="shape-image">
                              <div
                                className=" wow fadeInLeft"
                                data-wow-dealy="0.3s"
                              >
                                <img src="media\services\cloud_oprations\finops-vector.jpg" alt="finops" />
                              </div>
                            </div>

                            {/* <div className="parallax-image">
                              <div className="parallax-image-wrapper">
                                <ul className="parallax-image-list">
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.3s"
                                  >
                                    <img
                                      src="media/image-content/img_01.png"
                                      alt=""
                                    />
                                  </li>
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.3s"
                                  >
                                    <img
                                      src="media/image-content/img_02.png"
                                      alt=""
                                    />
                                  </li>
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.5s"
                                  >
                                    <img
                                      src="media/image-content/img_03.png"
                                      alt=""
                                    />
                                  </li>
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.5s"
                                  >
                                    <img
                                      src="media/image-content/img_04.png"
                                      alt=""
                                    />
                                  </li>
                                </ul>
                              </div>
                            </div> */}
                          </div>
                        </div>

                        <div className="col-xl-5">
                          <div className="image-content">
                            <SectionTitle
                              classOption="style-one"
                              firstTitle="Optimize Financial Performance"
                              description=" Apoyphe’s FinOps services focus on integrating financial management into cloud operations, enabling "
                              descriptionTwo=" organizations to achieve greater visibility, control, and efficiency in their cloud spending."
                              descriptionThree=" "
                            />

                            {/* <h4
                              className="title wow fadeInUp"
                              data-wow-delay="0.7s"
                            >
                              Discover the widgets
                            </h4> */}

                            {/* <a
                              href="about"
                              className="tt__btn btn-sqr btn-outline wow fadeInUp"
                              data-wow-delay="1.1s"
                            >
                              Read More
                            </a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <div className="service-three area">
                    <div className="container">
                      <SectionTitle
                        firstTitle="Cost Management"
                        lastTitle=""
                        description="Our comprehensive approach to FinOps ensures that you can proactively manage your cloud costs while maximizing the value derived from your cloud investments, fostering better financial accountability."
                      />
                      <div className="row">
                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.7s"
                          >
                            <div className="icon-container">
                              <img
                                src="media\services\cloud_oprations\finops-icon1.png"
                                alt="Direct Access"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="/service" style={{pointerEvents:'none'}}>Budget Tracking</Link>
                              </h4>

                              <p className="description">
                                We utilize tools like CloudHealth and CloudCheckr to provide transparent budget tracking and monitoring, helping you to keep your expenses aligned with your financial goals.
                              </p>
                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.9s"
                          >
                            <div className="icon-container color__two">
                              <img
                                src="media\services\cloud_oprations\finops-icon2.png"
                                alt="Push Notification"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Resource Allocation</Link>
                              </h4>

                              <p className="description">
                                Through advanced analytics, we help you optimize resource allocation, ensuring that your cloud resources are utilized effectively to minimize wastage and reduce costs.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media\services\cloud_oprations\finops-icon3.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Performance Metrics</Link>
                              </h4>

                              <p className="description">
                                Implementing cost allocation models allows you to track performance metrics related to spending, giving you insights to make informed financial decisions.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="service-three area">
                    <div className="container">
                      <SectionTitle
                        firstTitle="Strategic Planning"
                        lastTitle=""
                        description="Our FinOps strategy involves aligning financial and operational teams to create a culture of shared responsibility for financial outcomes, fostering collaboration and acco"
                      />
                      <div className="row">
                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.7s"
                          >
                            <div className="icon-container">
                              <img
                                src="media\services\cloud_oprations\finops-icon4.png"
                                alt="Direct Access"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="/service" style={{pointerEvents:'none'}}>Forecasting Tools</Link>
                              </h4>

                              <p className="description">
                                We leverage predictive analytics tools to forecast cloud spending trends, enabling you to plan your budgets more accurately and make proactive financial decisions.
                              </p>
                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.9s"
                          >
                            <div className="icon-container color__two">
                              <img
                                src="media\services\cloud_oprations\finops-icon5.png"
                                alt="Push Notification"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Usage Reporting</Link>
                              </h4>

                              <p className="description">
                                Our detailed usage reports provide insights into cloud consumption patterns, allowing for informed adjustments that lead to significant cost savings.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media\services\cloud_oprations\finops-icon6.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Optimization Frameworks</Link>
                              </h4>

                              <p className="description">
                                We implement optimization frameworks that continuously improve your cloud usage, identifying opportunities for cost reductions while maintaining performance.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media\services\cloud_oprations\finops-icon9.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Vendor Negotiation</Link>
                              </h4>

                              <p className="description">
                                Our team supports you in negotiating better contracts with cloud service providers, ensuring you receive the best possible pricing and terms for your cloud services.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media\services\cloud_oprations\finops-icon7.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Compliance Monitoring</Link>
                              </h4>

                              <p className="description">
                                We ensure that your cloud financial practices adhere to industry standards and regulations, helping you avoid potential penalties and enhance your financial governance.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media\services\cloud_oprations\finops-icon8.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Expert Consultation</Link>
                              </h4>

                              <p className="description">
                                Our experienced FinOps professionals offer tailored consultations to refine your financial strategies, ensuring alignment with business objectives and fostering sustainable growth.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {selectedSection === " SecOps " && (
                <div
                  className="mt-4 tab-content tt-tabs-content feature-tab-two"
                  id="nav-tabContent"
                >
                  <section className="image-content-area agriculture-section">
                    <div className="container">
                      <div className="row">
                        <div className="col-xl-6">
                          <div className="image-content">
                            <SectionTitle
                              classOption="style-one"
                              firstTitle="Increase your business growth & sales"
                              description=" He lost his bottle in my flat my good sir chap my lady boot"
                              descriptionTwo="is car boot well smashing crikey, are you taking the piss"
                              descriptionThree="lost the plot it's all gone."
                            />

                            {/* <h4
                              className="title wow fadeInUp"
                              data-wow-delay="0.7s"
                            >
                              Discover the widgets
                            </h4> */}

                            <a
                              href="about"
                              className="tt__btn btn-sqr btn-outline wow fadeInUp"
                              data-wow-delay="1.1s"
                            >
                              Read More
                            </a>
                          </div>
                        </div>

                        <div className="col-xl-6">
                          <div className="tt-parallax__image tt-parallax__image--one">
                            <div className="shape-image">
                              <div
                                className="shape-wrapper wow fadeInLeft"
                                data-wow-dealy="0.3s"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="100%"
                                  height="100%"
                                  viewBox="0 0 557 573"
                                >
                                  <path
                                    opacity="0.2"
                                    fill="rgb(42, 92, 255)"
                                    d="M340.000,39.000 C514.125,249.550 534.107,124.244 554.000,326.999 C567.156,461.088 521.668,652.432 389.000,538.000 C262.486,428.875 320.696,424.474 81.000,392.000 C-156.168,359.868 191.931,-140.044 340.000,39.000 Z"
                                  ></path>
                                </svg>
                              </div>
                            </div>

                            <div className="parallax-image">
                              <div className="parallax-image-wrapper">
                                <ul className="parallax-image-list">
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.3s"
                                  >
                                    <img
                                      src="media/image-content/img_01.png"
                                      alt=""
                                    />
                                  </li>
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.3s"
                                  >
                                    <img
                                      src="media/image-content/img_02.png"
                                      alt=""
                                    />
                                  </li>
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.5s"
                                  >
                                    <img
                                      src="media/image-content/img_03.png"
                                      alt=""
                                    />
                                  </li>
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.5s"
                                  >
                                    <img
                                      src="media/image-content/img_04.png"
                                      alt=""
                                    />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <div className="service-three area">
                    <div className="container">
                      <SectionTitle
                        firstTitle="Ultraland"
                        lastTitle="Trending feature"
                        description="So I said knees up cuppa such a fibber jeffrey a bit of how's your."
                      />
                      <div className="row">
                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.7s"
                          >
                            <div className="icon-container">
                              <img
                                src="media/feature/trending-up.svg"
                                alt="Direct Access"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="/service">Direct Access</Link>
                              </h4>

                              <p className="description">
                                Naff buggered I Eaton grub cheers show off show
                                off pick your nose and blow off give us a bell.
                              </p>
                              <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.9s"
                          >
                            <div className="icon-container color__two">
                              <img
                                src="media/feature/bell.svg"
                                alt="Push Notification"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service">Push Notification</Link>
                              </h4>

                              <p className="description">
                                Naff buggered I Eaton grub cheers show off show
                                off pick your nose and blow off give us a bell.
                              </p>

                              <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media/feature/tablet.svg"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service">Mobile Capability</Link>
                              </h4>

                              <p className="description">
                                Naff buggered I Eaton grub cheers show off show
                                off pick your nose and blow off give us a bell.
                              </p>

                              <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="service-three area">
                    <div className="container">
                      <SectionTitle
                        firstTitle="Ultraland"
                        lastTitle="Trending feature"
                        description="So I said knees up cuppa such a fibber jeffrey a bit of how's your."
                      />
                      <div className="row">
                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.7s"
                          >
                            <div className="icon-container">
                              <img
                                src="media/feature/trending-up.svg"
                                alt="Direct Access"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="/service">Direct Access</Link>
                              </h4>

                              <p className="description">
                                Naff buggered I Eaton grub cheers show off show
                                off pick your nose and blow off give us a bell.
                              </p>
                              <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.9s"
                          >
                            <div className="icon-container color__two">
                              <img
                                src="media/feature/bell.svg"
                                alt="Push Notification"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service">Push Notification</Link>
                              </h4>

                              <p className="description">
                                Naff buggered I Eaton grub cheers show off show
                                off pick your nose and blow off give us a bell.
                              </p>

                              <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media/feature/tablet.svg"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service">Mobile Capability</Link>
                              </h4>

                              <p className="description">
                                Naff buggered I Eaton grub cheers show off show
                                off pick your nose and blow off give us a bell.
                              </p>

                              <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media/feature/tablet.svg"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service">Mobile Capability</Link>
                              </h4>

                              <p className="description">
                                Naff buggered I Eaton grub cheers show off show
                                off pick your nose and blow off give us a bell.
                              </p>

                              <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media/feature/tablet.svg"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service">Mobile Capability</Link>
                              </h4>

                              <p className="description">
                                Naff buggered I Eaton grub cheers show off show
                                off pick your nose and blow off give us a bell.
                              </p>

                              <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media/feature/tablet.svg"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service">Mobile Capability</Link>
                              </h4>

                              <p className="description">
                                Naff buggered I Eaton grub cheers show off show
                                off pick your nose and blow off give us a bell.
                              </p>

                              <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default CloudOperationS1;
