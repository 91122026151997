import React from 'react';
// import FeatureFive from '../../components/Features/FeatureFive';
// import CounterFour from '../../components/Counter/CounterFour';
// import FooterTwo from '../../layout/Footer/FooterTwo';
import HeroSix from './HeroSix';
// import NewsLetterFour from '../../components/NewsLetter/NewsLetterFour';
// import ServiceFive from '../../components/Services/ServiceFive';
// import IntroThree from '../../components/IntroVideo/IntroThree';
// import FeaturedWork from '../../components/Features/FeaturedWork';
// import PromoBox from '../../components/PromoSection/PromoBox';
import Layout from '../../layout';
import PageMeta from '../../components/PageMeta';
// import NavRightTwo from '../../components/NavRight/NavRightTwo';
import Navbar from '../../layout/Header/Navbar';
// import ServiceThree from '../../components/Services/ServiceThree';
import FooterOne      from '../../layout/Footer/FooterOne';
import NavRightOne    from '../../components/NavRight/NavRightOne';
import CloudServicesS1 from '../../components/Services/CloudServicesS1';
// import CloudServiceS3 from '../../components/Services/CloudServiceS3';
// import CloudServiceS4 from '../../components/Services/CloudServiceS4';
// import CloudServiceS5 from '../../components/Services/CloudServiceS5';
// import CloudServiceS6 from '../../components/Services/CloudServiceS6';

const CloudServices = () => {
  return (
    <Layout>
      <PageMeta title='Cloud Services - Apoyphe' />
      <Navbar logoDark navRight={<NavRightOne warningBtn />} />
      <div id='main_content'>
        <HeroSix />
        <CloudServicesS1/>
        {/* <FeatureFive/> */}
        {/* <CloudServiceS3/>
        <CloudServiceS4/>
        <CloudServiceS5/>
        <CloudServiceS6/> */}
        {/* <CounterFour /> */}
        {/* <ServiceThree /> */}
        {/* <ServiceFive />
        <IntroThree /> */}
        {/* <FeaturedWork /> */}
        {/* <PromoBox /> */}
        {/* <NewsLetterFour /> */}
        <FooterOne />
      </div>
    </Layout>
  );
};

export default CloudServices;
