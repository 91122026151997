import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../SectionTitle/SectionTitle";

function ImageContentSeven() {
  return (
    <React.Fragment>
       <section className="image-content-area">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xl-6">
                <div className="tt-parallax__image tt-parallax__image--one">
                  <div className="">
                    <div
                      className="shape-wrapper wow fadeInLeft"
                      data-wow-dealy="0.3s"
                    >
                      <img
                        // className='wow fadeInDown'
                        data-wow-delay="ms"
                        src='media/home/Home-2.gif'
                        alt="banner 1"
                      />
                    </div>
                  </div>

                  {/* <div className='parallax-image'>
                    <div className='parallax-image-wrapper'>
                      <ul className='parallax-image-list'>
                        <li className='wow fadeInUp' data-wow-delay='0.3s'>
                          <img src='media/image-content/img_01.png' alt='' />
                        </li>
                        <li className='wow fadeInUp' data-wow-delay='0.3s'>
                          <img src='media/image-content/img_02.png' alt='' />
                        </li>
                        <li className='wow fadeInUp' data-wow-delay='0.5s'>
                          <img src='media/image-content/img_03.png' alt='' />
                        </li>
                        <li className='wow fadeInUp' data-wow-delay='0.5s'>
                          <img src='media/image-content/img_04.png' alt='' />
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="col-xl-6">
                <div className="image-content" style={{paddingTop:"90px"}}>
                  <SectionTitle
                    classOption="style-one"
                    firstTitle="We lead and support our customer’s cloud"
                    description=" Our mission is to deliver high-performance cloud architectures that refine business processes and drive innovations. With expertise in cloud migration and modernization, we create agile environments that meet governance"
                    descriptionTwo="  and security needs. We simplify complex technology landscapes with scalable, future-ready solutions for evolving enterprise demands."
                    // descriptionThree="Using cutting-edge cloud tools and frameworks, we help businesses stay competitive in an ever-evolving digital landscape."
                  />

                  {/* <h4 className="title wow fadeInUp" data-wow-delay="0.7s">
                    Discover the widgets
                  </h4> */}

                  {/* <div className="row">
                    <div className="col-xl-6">
                      <ul
                        className="tt-list wow fadeInUp"
                        data-wow-delay="0.9s"
                      >
                        <li className="list-item">
                          <i className="feather-check"></i>
                          <span className="list-text">Additional </span>
                        </li>

                        {/* <li className="list-item">
                          <i className="feather-check"></i>
                          <span className="list-text">Table</span>
                        </li> *
                        <li className="list-item">
                          <i className="feather-check"></i>
                          <span className="list-text">Cross</span>
                        </li>
                      </ul>
                    </div>

                    <div className="col-xl-6">
                      <ul
                        className="tt-list wow fadeInUp"
                        data-wow-delay="0.9s"
                      >
                        <li className="list-item">
                          <i className="feather-check"></i>
                          <span className="list-text">Content</span>
                        </li>

                        <li className="list-item">
                          <i className="feather-check"></i>
                          <span className="list-text">Design</span>
                        </li>
                      </ul>
                    </div>
                  </div> */}

                  <Link
                    to="/about-us"
                    className="tt__btn btn-sqr btn-outline wow fadeInUp "
                    data-wow-delay="1.1s"
                  >
                  Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

      {/* 
      <section className='about-education pt-6'>
        <div className='container'>

          <div className="row gy-4 align-items-stretch justify-content-between features-item ">
            <div className="col-lg-6 d-flex align-items-center features-img-bg aos-init aos-animate" data-aos="zoom-out">
              <img src='media/banner/banner-one/Home 2_1-08.png' className="img-fluid" alt="" />
            </div>
            <div className="col-lg-5 d-flex justify-content-center flex-column aos-init aos-animate" data-aos="fade-up">
              <h3>Sunt consequatur ad ut est nulla</h3>
              <p>Cupiditate placeat cupiditate placeat est ipsam culpa. Delectus quia minima quod. Sunt saepe odit aut quia voluptatem hic voluptas dolor doloremque.</p>
              <ul>
                <li><i className="bi bi-check"></i> <span>Ullamco laboris nisi ut aliquip ex ea commodo consequat.</span></li>
                <li><i className="bi bi-check"></i><span> Duis aute irure dolor in reprehenderit in voluptate velit.</span></li>
                <li><i className="bi bi-check"></i> <span>Facilis ut et voluptatem aperiam. Autem soluta ad fugiat</span>.</li>
              </ul>
              <Link href="#" className="btn btn-get-started align-self-start">Get Started</Link>
            </div>
          </div>
        </div>
      </section> */}
    </React.Fragment>
  );
}

export default ImageContentSeven;
