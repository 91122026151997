import React from 'react';
import ServiceThree from '../../components/Services/ServiceThree';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
import ImageContentSix from '../../components/ImageContent/ImageContentSix';
import Layout from '../../layout';
import PageMeta from '../../components/PageMeta/index';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import NavRightOne from '../../components/NavRight/NavRightOne';
import FeatureOne from '../../components/Features/FeatureOne';

const Aboutus = () => {
  return (
    <Layout>
      <PageMeta title='About Us - Apoyphe' />
      <Navbar logoDark navRight={<NavRightOne />} />
      <div id='main_content'>
        <BreadCrumb breadCrumbTitle='About Us' pageName='About Us' />
        <ServiceThree />
        <ImageContentSix />
        <FeatureOne />
        <FooterOne />
      </div>
    </Layout>
  );
};

export default Aboutus;
