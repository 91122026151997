// import React, { useState } from 'react';
import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import axios from 'axios';
// import Map from '../../components/Map';

const ContactFormMap = () => {



  const [status, setStatus] = useState('');
  const [timerId, setTimerId] = useState(null); // To store timer ID

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });


  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('http://localhost:5000/send-email', formData);

      if (response.status === 200) {
        setStatus('Email sent successfully!');
        setFormData({
          name: '',
          email: '',
          message: '',
        });
      } else {
        setStatus('Failed to send email. Please try again.');
      }
    } catch (error) {
      setStatus('Failed to send email. Please try again.');
    }

    // Clear previous timeout if exists
    if (timerId) {
      clearTimeout(timerId);
    }

    // Clear status message after 5 seconds
    const id = setTimeout(() => {
      setStatus('');
    }, 20000); // 5000 milliseconds = 5 seconds

    setTimerId(id); // Store the timer ID
  };

  // Clean up timeout if the component unmounts or status changes
  useEffect(() => {
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [timerId]);

  // Determine the style based on the status message
  const getStatusStyle = () => {
    if (status.includes('successfully')) {
      return { color: 'green', fontSize: '22px' };
    }
    return { color: 'red', fontSize: '22px' };
  };





  return (
    <React.Fragment>
      <section className='contact-page'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-12 pr'>
              <div className='row align-items-center pr'>
                <div className='col-md-6'>
                  <div className='contact-form-wrapper'>
                    <div className='contact-content'>
                      <h2 className='contact-title'>Send us a Message</h2>
                      <p className='description'>
                        {/* Why I say old chap that is spiffing cockup off his nut blow. */}
                      </p>
                    </div>

                    <form onSubmit={handleSubmit}

                      className='contact-form'
                      data-tt-form='contact-froms'
                    >
                      <div className='input-wrap'>
                        <label>Your Name</label>
                        <div className='input-field'>
                          <input
                            type='text'
                            placeholder='Name'
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                          <i className='feather-user'></i>
                        </div>
                      </div>

                      <div className='input-wrap'>
                        <label>Email</label>
                        <div className='input-field'>
                          <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder='Email'
                            required
                          />
                          <i className='feather-mail'></i>
                        </div>
                      </div>

                      <div className='input-wrap mb--5'>
                        <label>Message</label>
                        <div className='input-field message-field'>
                          <textarea
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            placeholder='Type your message'
                            required

                          ></textarea>
                          <i className='feather-message-square'></i>
                        </div>
                      </div>

                      

                      <button type='submit' className='tt__btn submit-btn'>
                        <span className='btn-text'>Send</span>
                        <i className='fas fa-spinner fa-spin'></i>
                      </button>

                      <div className='form-result alert'>
                        <div className='content'></div>
                      </div>
                    </form>

                    <div>
                      <h4 className='pt-4'>
                      {status && <p style={getStatusStyle()}>{status}</p>}
                      </h4>
                    </div>
                  </div>
                </div>

                <div className='col-md-6'>
                  <div className='google-map'>
                    {/* <Map /> */}
                    <img src='media/contact_img/contact-img.jpg' alt='contact-img' />
                  </div>
                </div>
              </div>

              <ul className='anemate-element parallax-element'>
                <li className='layer' data-depth='0.05'>
                  <div className='inner'>
                    <img src='media/element/sf1.png' alt='shape' />
                  </div>
                </li>

                <li className='layer' data-depth='0.02'>
                  <div className='inner'>
                    <img src='media/element/sf3.png' alt='shape' />
                  </div>
                </li>

                <li className='layer' data-depth='0.04'>
                  <div className='inner'>
                    <img src='media/element/sf4.png' alt='shape' />
                  </div>
                </li>
              </ul>

            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ContactFormMap;
