import React, { Component } from 'react';
import SectionTitle from '../SectionTitle/SectionTitle';

class ServiceTwo extends Component {
  render() {
    return (
      <React.Fragment>
        <div className='service'>
          <div className='container'>
            <SectionTitle
              firstTitle='What We'
              lastTitle='Bring to You'
              description="Our cloud offerings cater to enterprise-level IT needs, covering IaaS, PaaS, and SaaS. We deliver advanced computing, storage, and AI-driven capabilities tailored for complex workloads and critical operations."
            />
            <div className='row'>
              <div className='col-lg-4 col-md-6 col-sm-6'>
                <div
                  className='tt-icon-box style-two wow fadeInUp'
                  data-wow-delay='0.7s'
                >
                  <div className='icon-container color__one'>
                    <img
                      src='media/home/Cloud Migration.gif'
                      alt='Direct Access'
                    />
                  </div>

                  <div className='box-content'>
                    <h4 className='box-title'>
                      <a href='service.html'  style={{ pointerEvents: 'none' }}>Cloud Architecture & Strategy</a>
                    </h4>

                    <p className='description'>
                      We design cloud architectures aligned with your business strategy.
                    </p>

                    {/* <a href='service.html' className='tt-more-link'>
                      Read More<i className='feather-arrow-right'></i>
                    </a> */}
                  </div>
                </div>
              </div>

              <div className='col-lg-4 col-md-6 col-sm-6'>
                <div
                  className='tt-icon-box style-two wow fadeInUp'
                  data-wow-delay='0.9s'
                >
                  <div className='icon-container color__two'>
                    <img src='media/home/Cloud Service.gif' alt='Push Notification' />
                  </div>
                  <div className='box-content'>
                    <h4 className='box-title'>
                      <a href='service'  style={{ pointerEvents: 'none' }}>Cloud Operations</a>
                    </h4>

                    <p className='description'>
                      We manage cloud operations for optimal performance and efficiency..
                    </p>
                    {/* <a href='service' className='tt-more-link'>
                      Read More<i className='feather-arrow-right'></i>
                    </a> */}
                  </div>
                </div>
              </div>

              <div className='col-lg-4 col-md-6 col-sm-6'>
                <div
                  className='tt-icon-box style-two wow fadeInUp'
                  data-wow-delay='1.1s'
                >
                  <div className='icon-container color__three'>
                    <img
                      src='media/home/Infrastructure Management(2).gif'
                      alt='Mobile Capability'
                    />
                  </div>

                  <div className='box-content'>
                    <h4 className='box-title'>
                      <a href='service.html'  style={{ pointerEvents: 'none' }}>Managed Cloud Services</a>
                    </h4>

                    <p className='description'>
                      We provide complete management of your cloud environment's needs.
                    </p>

                    {/* <a href='service.html' className='tt-more-link'>
                      Read More<i className='feather-arrow-right'></i>
                    </a> */}
                  </div>
                </div>
              </div>

              <div className='col-lg-4 col-md-6 col-sm-6'>
                <div
                  className='tt-icon-box style-two wow fadeInUp'
                  data-wow-delay='1.3s'
                >
                  <div className='icon-container color__four'>
                    <img src='media/home/Software Services.gif' alt='Direct Access' />
                  </div>

                  <div className='box-content'>
                    <h4 className='box-title'>
                      <a href='service.html'  style={{ pointerEvents: 'none' }}>Software Integration Services</a>
                    </h4>

                    <p className='description'>
                      We integrate software solutions to enhance functionality and efficiency.
                    </p>

                    {/* <a href='service' className='tt-more-link'>
                      Read More<i className='feather-arrow-right'></i>
                    </a> */}
                  </div>
                </div>
              </div>

              <div className='col-lg-4 col-md-6 col-sm-6'>
                <div
                  className='tt-icon-box style-two wow fadeInUp'
                  data-wow-delay='1.5s'
                >
                  <div className='icon-container color__five'>
                    <img
                      src='media/home/Cloud Security.gif'
                      alt='Push Notification'
                    />
                  </div>

                  <div className='box-content'>
                    <h4 className='box-title'>
                      <a href='service.html'  style={{ pointerEvents: 'none' }}>Cloud Security Solutions</a>
                    </h4>

                    <p className='description'>
                      We ensure robust protection for your cloud environment and data.
                    </p>

                    {/* <a href='service.html' className='tt-more-link'>
                      Read More<i className='feather-arrow-right'></i>
                    </a> */}
                  </div>
                </div>
              </div>

              <div className='col-lg-4 col-md-6 col-sm-6'>
                <div
                  className='tt-icon-box style-two wow fadeInUp'
                  data-wow-delay='1.7s'
                >
                  <div className='icon-container color__six'>
                    <img
                      src='media/home/Infrastructure Management.gif'
                      alt='Mobile Capability'
                    />
                  </div>

                  <div className='box-content'>
                    <h4 className='box-title'>
                      <a href='service.html'  style={{ pointerEvents: 'none' }}>Data Backup & Recovery</a>
                    </h4>

                    <p className='description'>
                    We offer reliable backup and rapid recovery for your critical data needs.
                    </p>
{/* 
                    <a href='service.html' className='tt-more-link'>
                      Read More<i className='feather-arrow-right'></i>
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceTwo;
