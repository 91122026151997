import React, { useState } from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../SectionTitle/SectionTitle";

const ManagedServiceS1 = () => {
  const [selectedSection, setSelectedSection] = useState(
    "InfrastructureServices"
  );

  // Method to handle button clicks and update the selected section
  const handleSectionClick = (section) => {
    setSelectedSection(section);
  };

  return (
    <React.Fragment>
      <section className="feature-tabs-three">
        <div className="container-fluid">
          <SectionTitle
            firstTitle="Management of cloud resources"
            lastTitle=""
            description="Comprehensive management of your IT infrastructure, offering proactive support and optimization."
            classOption="text-center"
          />
          <div
            className="tt-feature-tab-two wow fadeInUp"
            data-wow-delay="0.7s"
          >
            <nav>
              <div
                className="nav d-flex justify-content-center nav-pills tt-tabs-navigation feature-tab-two-nav"
                id="nav-tab"
                role="tablist"
              >
                <a
                  className="nav-link tab-nav-item feature-tab-two-nav__item active"
                  id="nav-home-tab"
                  data-toggle="tab"
                  href="#Infrastructure-Services"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="true"
                  onClick={() => handleSectionClick("InfrastructureServices")}
                >
                  <div className="tab-icon">
                    {/* <img src="media/feature/tab1.svg" alt="tab" /> */}
                    <i class="bi bi-building"></i>
                  </div>
                  <div className="nav_content">
                    <h4 className="feature-tab-two-nav__title">
                      Infrastructure Services
                    </h4>
                  </div>
                </a>

                <a
                  className="nav-link tab-nav-item feature-tab-two-nav__item"
                  id="nav-profile-tab"
                  data-toggle="tab"
                  href="#Maintenance"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                  onClick={() => handleSectionClick("Maintenance")}
                >
                  <div className="tab-icon">
                    {/* <img src="media/feature/tab2.svg" alt="tab" /> */}
                    <i class="bi bi-gear-fill"></i>
                  </div>

                  <div className="nav_content">
                    <h4 className="feature-tab-two-nav__title">Maintenance</h4>
                  </div>
                </a>

                <a
                  className="nav-link disabled tab-nav-item feature-tab-two-nav__item"
                  id="nav-profile-tab"
                  data-toggle="tab"
                  href="#M365OPS"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                  onClick={() => handleSectionClick("M365OPS")}
                >
                  <div className="tab-icon">
                    {/* <img src="media/feature/tab2.svg" alt="tab" /> */}
                    <i class="fa-brands fa-windows"></i>
                  </div>

                  <div className="nav_content">
                    <h4 className="feature-tab-two-nav__title">M365 OPS</h4>
                  </div>
                </a>
              </div>
            </nav>

            <div>
              {selectedSection === "InfrastructureServices" && (
                <div
                  className="mt-4 tab-content tt-tabs-content feature-tab-two"
                  id="nav-tabContent"
                >
                  <section className="image-content-area agriculture-section">
                    <div className="container">
                      <div className="row">
                        <div className="col-xl-7">
                          <div className="tt-parallax__image tt-parallax__image--one">
                            <div className="shape-image">
                              <div
                                className="shape-wrapper wow fadeInLeft"
                                data-wow-dealy="0.3s"
                              >
                                <img
                                  src="media\services\managed_services\infra-services-vector.avif"
                                  alt="infra-services"
                                />
                              </div>
                            </div>

                            {/* <div className="parallax-image">
                              <div className="parallax-image-wrapper">
                                <ul className="parallax-image-list">
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.3s"
                                  >
                                    <img
                                      src="media/image-content/img_01.png"
                                      alt=""
                                    />
                                  </li>
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.3s"
                                  >
                                    <img
                                      src="media/image-content/img_02.png"
                                      alt=""
                                    />
                                  </li>
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.5s"
                                  >
                                    <img
                                      src="media/image-content/img_03.png"
                                      alt=""
                                    />
                                  </li>
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.5s"
                                  >
                                    <img
                                      src="media/image-content/img_04.png"
                                      alt=""
                                    />
                                  </li>
                                </ul>
                              </div>
                            </div> */}
                          </div>
                        </div>

                        <div className="col-xl-5">
                          <div className="image-content">
                            <SectionTitle
                              classOption="style-one"
                              firstTitle="Robust Infrastructure Solutions"
                              description=" Apoyphe’s cloud infrastructure services are designed to provide flexible, scalable,and reliable solutions that"
                              descriptionTwo=" empower businesses to thrive in the digital era while"
                              descriptionThree="ensuring optimal performance and security."
                            />

                            {/* <h4
                              className="title wow fadeInUp"
                              data-wow-delay="0.7s"
                            >
                              Discover the widgets
                            </h4> */}

                            {/* <a
                              href="about"
                              className="tt__btn btn-sqr btn-outline wow fadeInUp"
                              data-wow-delay="1.1s"
                            >
                              Read More
                            </a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <div className="service-three area">
                    <div className="container">
                      <SectionTitle
                        firstTitle="Seamless Integration"
                        lastTitle=""
                        description="Our infrastructure services seamlessly integrate with your existing IT environment, ensuring minimal disruption while enhancing performance, security, and efficiency across all platforms."
                      />
                      <div className="row">
                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.7s"
                          >
                            <div className="icon-container">
                              <img
                                src="media\services\managed_services\managed-services-icon1.png"
                                alt="Direct Access"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="/service" style={{pointerEvents:'none'}}>Monitoring Tools</Link>
                              </h4>

                              <p className="description">
                                We employ advanced monitoring tools like Datadog
                                and Prometheus to provide real-time insights
                                into your cloud infrastructure, allowing for
                                proactive issue resolution.
                              </p>
                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.9s"
                          >
                            <div className="icon-container color__two">
                              <img
                                src="media\services\managed_services\managed-services-icon2.png"
                                alt="Push Notification"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>
                                  Automation Capabilities
                                </Link>
                              </h4>

                              <p className="description">
                                Our automation capabilities streamline
                                operations through Infrastructure as Code (IaC)
                                practices, enabling efficient resource
                                provisioning and management while reducing
                                errors.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media\services\managed_services\managed-services-icon3.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Disaster Recovery</Link>
                              </h4>

                              <p className="description">
                                We implement comprehensive disaster recovery
                                solutions that safeguard your data and
                                applications, ensuring business continuity in
                                the event of unexpected incidents or outages.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="service-three area">
                    <div className="container">
                      <SectionTitle
                        firstTitle="Optimized Performance"
                        lastTitle=""
                        description="With a focus on performance optimization, our services are tailored to meet the specific needs of your business, ensuring that your infrastructure supports your growth ambitions."
                      />
                      <div className="row">
                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.7s"
                          >
                            <div className="icon-container">
                              <img
                                src="media\services\managed_services\managed-services-icon4.png"
                                alt="Direct Access"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="/service" style={{pointerEvents:'none'}}>Cost Efficiency</Link>
                              </h4>

                              <p className="description">
                                We utilize tools like CloudHealth and Spot.io to
                                analyze usage patterns and optimize costs,
                                helping you achieve significant savings without
                                compromising service quality.
                              </p>
                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.9s"
                          >
                            <div className="icon-container color__two">
                              <img
                                src="media\services\managed_services\managed-services-icon5.png"
                                alt="Push Notification"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Security Frameworks</Link>
                              </h4>

                              <p className="description">
                                Our infrastructure services are built on robust
                                security frameworks that protect your data and
                                applications, ensuring compliance with industry
                                regulations and best practices.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media\services\managed_services\managed-services-icon6.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Scalability Features</Link>
                              </h4>

                              <p className="description">
                                We design scalable infrastructure solutions that
                                can grow with your business, providing the
                                flexibility to adjust resources based on
                                changing demands and workloads.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media\services\managed_services\managed-services-icon7.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Network Performance</Link>
                              </h4>

                              <p className="description">
                                Our services include network performance
                                optimization, ensuring that your applications
                                run smoothly and efficiently, enhancing the
                                overall user experience.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media\services\managed_services\managed-services-icon8.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Collaboration Tools</Link>
                              </h4>

                              <p className="description">
                                We leverage collaboration tools like Terraform
                                and Ansible to foster team collaboration and
                                infrastructure management, making it easier to
                                manage complex environments.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media\services\managed_services\managed-services-icon9.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Expert Support</Link>
                              </h4>

                              <p className="description">
                                Our experienced support team is available 24/7
                                to provide guidance and assistance with your
                                cloud infrastructure, ensuring that you have the
                                resources you need to succeed.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {selectedSection === "Maintenance" && (
                <div
                  className="mt-4 tab-content tt-tabs-content feature-tab-two"
                  id="nav-tabContent"
                >
                  <section className="image-content-area agriculture-section">
                    <div className="container">
                      <div className="row justify-content-between">
                        <div className="col-xl-6">
                          <div className="image-content">
                            <SectionTitle
                              classOption="style-one"
                              firstTitle="Optimize Your Cloud Performance"
                              description="Our cloud maintenance services are designed to ensure your infrastructure operates at peak efficiency, providing continuous monitoring,"
                              descriptionTwo="  maintenance, and optimization to support your"
                              descriptionThree="business growth."
                            />

                            {/* <h4
                              className="title wow fadeInUp"
                              data-wow-delay="0.7s"
                            >
                              Discover the widgets
                            </h4> */}

                            {/* <a
                              href="about"
                              className="tt__btn btn-sqr btn-outline wow fadeInUp"
                              data-wow-delay="1.1s"
                            >
                              Read More
                            </a> */}
                          </div>
                        </div>

                        <div className="col-xl-5">
                          <div className="tt-parallax__image tt-parallax__image--one">
                            <div className="shape-image">
                              <div
                                className="shape-wrapper wow fadeInLeft"
                                data-wow-dealy="0.3s"
                              >
                                <img
                                  src="media\services\managed_services\maintainance-vector.jpg"
                                  alt="cloud-maintainance"
                                />
                              </div>
                            </div>

                            {/* <div className="parallax-image">
                              <div className="parallax-image-wrapper">
                                <ul className="parallax-image-list">
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.3s"
                                  >
                                    <img
                                      src="media/image-content/img_01.png"
                                      alt=""
                                    />
                                  </li>
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.3s"
                                  >
                                    <img
                                      src="media/image-content/img_02.png"
                                      alt=""
                                    />
                                  </li>
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.5s"
                                  >
                                    <img
                                      src="media/image-content/img_03.png"
                                      alt=""
                                    />
                                  </li>
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.5s"
                                  >
                                    <img
                                      src="media/image-content/img_04.png"
                                      alt=""
                                    />
                                  </li>
                                </ul>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <div className="service-three area">
                    <div className="container">
                      <SectionTitle
                        firstTitle="Real-Time Analytics"
                        lastTitle=""
                        description="By leveraging real-time analytics, we identify performance bottlenecks and make necessary adjustments to keep your cloud environment running smoothly and efficiently."
                      />
                      <div className="row">
                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.7s"
                          >
                            <div className="icon-container">
                              <img
                                src="media\services\managed_services\maintainance-icon1.png"
                                alt="Direct Access"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="/service" style={{pointerEvents:'none'}}>System Health</Link>
                              </h4>

                              <p className="description">
                                We utilize tools like Nagios and CloudWatch to
                                constantly monitor the health of your cloud
                                systems, ensuring early detection of issues and
                                proactive resolutions.
                              </p>
                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.9s"
                          >
                            <div className="icon-container color__two">
                              <img
                                src="media\services\managed_services\maintainance-icon2.png"
                                alt="Push Notification"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Patch Management</Link>
                              </h4>

                              <p className="description">
                                Our automated patch management ensures your
                                systems are always up-to-date, applying the
                                latest security patches and software updates
                                without interrupting business operations.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media\services\managed_services\maintainance-icon3.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Backup Services</Link>
                              </h4>

                              <p className="description">
                                We implement robust backup strategies using
                                tools like Veeam and Acronis, ensuring your
                                critical data is always safe, accessible, and
                                easily recoverable.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="service-three area">
                    <div className="container">
                      <SectionTitle
                        firstTitle="Proactive "
                        lastTitle="Maintenance for Longevity"
                        description="Our proactive approach to cloud maintenance ensures that your systems remain stable, secure, and optimized for long-term operational success."
                      />
                      <div className="row">
                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.7s"
                          >
                            <div className="icon-container">
                              <img
                                src="media\services\managed_services\maintainance-icon4.png"
                                alt="Direct Access"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="/service" style={{pointerEvents:'none'}}>Capacity Planning</Link>
                              </h4>

                              <p className="description">
                                We use tools like CloudHealth and Turbonomic for
                                capacity planning, ensuring your resources are
                                efficiently utilized and scale.
                              </p>
                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.9s"
                          >
                            <div className="icon-container color__two">
                              <img
                                src="media\services\managed_services\maintainance-icon5.png"
                                alt="Push Notification"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Downtime Prevention</Link>
                              </h4>

                              <p className="description">
                                Our downtime prevention strategies involve
                                predictive analytics to address potential system
                                failures before they occur to minimizing
                                disruptions.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media\services\managed_services\maintainance-icon6.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Resource Optimization</Link>
                              </h4>

                              <p className="description">
                                With tools like AWS Trusted Advisor and Azure
                                Advisor, we optimize cloud resources,
                                identifying underutilized assets and helping you
                                lower costs.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media\services\managed_services\maintainance-icon7.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Security Audits</Link>
                              </h4>

                              <p className="description">
                                We conduct regular security audits using tools like Prisma Cloud to ensure your infrastructure adheres to the highest security standards and compliance regulations.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media\services\managed_services\maintainance-icon8.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Service Automation</Link>
                              </h4>

                              <p className="description">
                                Our automation tools like Puppet and Chef simplify routine cloud maintenance tasks, enabling faster response times and reducing human error in operations.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media\services\managed_services\maintainance-icon9.png"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service" style={{pointerEvents:'none'}}>Disaster Recovery</Link>
                              </h4>

                              <p className="description">
                                We offer disaster recovery planning with services like Zerto and Rubrik, ensuring quick recovery times and business continuity in the event of a disaster.
                              </p>

                              {/* <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {selectedSection === "M365OPS" && (
                <div
                  className="mt-4 tab-content tt-tabs-content feature-tab-two"
                  id="nav-tabContent"
                >
                  <section className="image-content-area agriculture-section">
                    <div className="container">
                      <div className="row">
                        <div className="col-xl-7">
                          <div className="tt-parallax__image tt-parallax__image--one">
                            <div className="shape-image">
                              <div
                                className="shape-wrapper wow fadeInLeft"
                                data-wow-dealy="0.3s"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="100%"
                                  height="100%"
                                  viewBox="0 0 557 573"
                                >
                                  <path
                                    opacity="0.2"
                                    fill="rgb(42, 92, 255)"
                                    d="M340.000,39.000 C514.125,249.550 534.107,124.244 554.000,326.999 C567.156,461.088 521.668,652.432 389.000,538.000 C262.486,428.875 320.696,424.474 81.000,392.000 C-156.168,359.868 191.931,-140.044 340.000,39.000 Z"
                                  ></path>
                                </svg>
                              </div>
                            </div>

                            <div className="parallax-image">
                              <div className="parallax-image-wrapper">
                                <ul className="parallax-image-list">
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.3s"
                                  >
                                    <img
                                      src="media/image-content/img_01.png"
                                      alt=""
                                    />
                                  </li>
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.3s"
                                  >
                                    <img
                                      src="media/image-content/img_02.png"
                                      alt=""
                                    />
                                  </li>
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.5s"
                                  >
                                    <img
                                      src="media/image-content/img_03.png"
                                      alt=""
                                    />
                                  </li>
                                  <li
                                    className="wow fadeInUp"
                                    data-wow-delay="0.5s"
                                  >
                                    <img
                                      src="media/image-content/img_04.png"
                                      alt=""
                                    />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-5">
                          <div className="image-content">
                            <SectionTitle
                              classOption="style-one"
                              firstTitle="Increase your business growth & sales"
                              description=" He lost his bottle in my flat my good sir chap my lady boot"
                              descriptionTwo="is car boot well smashing crikey, are you taking the piss"
                              descriptionThree="lost the plot it's all gone."
                            />

                            <h4
                              className="title wow fadeInUp"
                              data-wow-delay="0.7s"
                            >
                              Discover the widgets
                            </h4>

                            <a
                              href="about"
                              className="tt__btn btn-sqr btn-outline wow fadeInUp mt-5"
                              data-wow-delay="1.1s"
                            >
                              Read More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <div className="service-three area">
                    <div className="container">
                      <SectionTitle
                        firstTitle="Ultraland"
                        lastTitle="Trending feature"
                        description="So I said knees up cuppa such a fibber jeffrey a bit of how's your."
                      />
                      <div className="row">
                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.7s"
                          >
                            <div className="icon-container">
                              <img
                                src="media/feature/trending-up.svg"
                                alt="Direct Access"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="/service">Direct Access</Link>
                              </h4>

                              <p className="description">
                                Naff buggered I Eaton grub cheers show off show
                                off pick your nose and blow off give us a bell.
                              </p>
                              <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.9s"
                          >
                            <div className="icon-container color__two">
                              <img
                                src="media/feature/bell.svg"
                                alt="Push Notification"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service">Push Notification</Link>
                              </h4>

                              <p className="description">
                                Naff buggered I Eaton grub cheers show off show
                                off pick your nose and blow off give us a bell.
                              </p>

                              <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media/feature/tablet.svg"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service">Mobile Capability</Link>
                              </h4>

                              <p className="description">
                                Naff buggered I Eaton grub cheers show off show
                                off pick your nose and blow off give us a bell.
                              </p>

                              <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="service-three area">
                    <div className="container">
                      <SectionTitle
                        firstTitle="Ultraland"
                        lastTitle="Trending feature"
                        description="So I said knees up cuppa such a fibber jeffrey a bit of how's your."
                      />
                      <div className="row">
                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.7s"
                          >
                            <div className="icon-container">
                              <img
                                src="media/feature/trending-up.svg"
                                alt="Direct Access"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="/service">Direct Access</Link>
                              </h4>

                              <p className="description">
                                Naff buggered I Eaton grub cheers show off show
                                off pick your nose and blow off give us a bell.
                              </p>
                              <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="0.9s"
                          >
                            <div className="icon-container color__two">
                              <img
                                src="media/feature/bell.svg"
                                alt="Push Notification"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service">Push Notification</Link>
                              </h4>

                              <p className="description">
                                Naff buggered I Eaton grub cheers show off show
                                off pick your nose and blow off give us a bell.
                              </p>

                              <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media/feature/tablet.svg"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service">Mobile Capability</Link>
                              </h4>

                              <p className="description">
                                Naff buggered I Eaton grub cheers show off show
                                off pick your nose and blow off give us a bell.
                              </p>

                              <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media/feature/tablet.svg"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service">Mobile Capability</Link>
                              </h4>

                              <p className="description">
                                Naff buggered I Eaton grub cheers show off show
                                off pick your nose and blow off give us a bell.
                              </p>

                              <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media/feature/tablet.svg"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service">Mobile Capability</Link>
                              </h4>

                              <p className="description">
                                Naff buggered I Eaton grub cheers show off show
                                off pick your nose and blow off give us a bell.
                              </p>

                              <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div
                            className="tt-icon-box style-three wow fadeInUp"
                            data-wow-delay="1.1s"
                          >
                            <div className="icon-container color__three">
                              <img
                                src="media/feature/tablet.svg"
                                alt="Mobile Capability"
                              />
                            </div>

                            <div className="box-content">
                              <h4 className="box-title">
                                <Link to="service">Mobile Capability</Link>
                              </h4>

                              <p className="description">
                                Naff buggered I Eaton grub cheers show off show
                                off pick your nose and blow off give us a bell.
                              </p>

                              <Link to="/service" className="tt-more-link">
                                Read More
                                <i className="feather-arrow-right"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ManagedServiceS1;
